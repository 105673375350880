<template>
  <v-container fluid class="pa-0">
    <v-card elevation="0" class="pa-0">
      <v-card-title flat>
        <!--  <v-icon left>mdi-chart-line</v-icon> Resumen de Progreso
        <v-spacer></v-spacer> -->

        <v-responsive class="d-none d-md-flex">
          <v-btn-toggle v-model="selectedPeriod" mandatory class="mr-4" @change="updateDateFilter">
            <v-btn value="week">Ultima Semana</v-btn>
            <v-btn value="month">Ultimo Mes</v-btn>
            <v-btn value="3months">Ultimos 3 Meses</v-btn>
            <v-btn value="all">Todo</v-btn>
          </v-btn-toggle>
        </v-responsive>


        <v-select v-if="$vuetify.breakpoint.smAndDown" filled v-model="selectedPeriod" :items="[
          { text: 'Ultima Semana', value: 'week' },
          { text: 'Ultimo Mes', value: 'month' },
          { text: 'Ultimos 3 Meses', value: '3months' },
          { text: 'Todo', value: 'all' }
        ]" label="Seleccionar Periodo" @change="updateDateFilter" hide-details=""></v-select>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="false" fab small v-bind="attrs" v-on="on" :color="returnColor(selectedType)" elevation="2">
              <v-icon>{{ exerciseTypes.find(type => type.value === selectedType).icon }}</v-icon>
            </v-btn>
            <v-btn v-else large class="mt-1" :color="returnColor(selectedType)" elevation="2"
              @click="selectedType = type.value" v-bind="attrs" v-on="on" :block="$vuetify.breakpoint.smAndDown">
              <v-icon left>{{ exerciseTypes.find(type => type.value === selectedType)?.icon || 'mdi-filter-variant'
                }}</v-icon>
              {{ exerciseTypes.find(type => type.value === selectedType)?.text || 'Todas' }}
            </v-btn>
          </template>
          <v-list class="py-0" dense>
            <template v-for="type in filteredExerciseTypes">
              <v-list-item :key="type.value" @click="selectedType = type.value">
                <v-list-item-icon>
                  <v-icon :color="returnColor(type.value)">{{ type.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ type.text }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>


        <!-- 
        <v-btn-toggle  v-model="selectedType" mandatory v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn v-for="type in exerciseTypes" :key="type.value" :value="type.value" :color="selectedType === type.value ? returnColor(type.value) : ''">
            <v-icon>{{ type.icon }}</v-icon>
          </v-btn>
        </v-btn-toggle> -->




      </v-card-title>

      <v-skeleton-loader v-if="loading" type="image" class="mx-4 mb-4"></v-skeleton-loader>
      <v-alert type="info" text v-if="!loading && getFilteredExercises.length === 0" class="mx-4 ">
        No hay datos disponibles en el periodo seleccionado.
      </v-alert>

      <v-card-text v-if="!loading && exercises.length > 0">


        <div class="masonry">
          <div class="masonry-item" v-for="(exercise, exIndex) in getFilteredExercises" :key="exIndex">
            <v-card class="mb-4" outlined>
              <v-toolbar flat>

                <v-avatar size="30" class="rounded-lg mr-2" :color="returnColor(exercise.type)">
                  <v-icon>{{ getExerciseIcon(exercise.type) }}</v-icon></v-avatar>
                {{ exercise.name }}
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-card-subtitle>
                <p v-if="exercise.type != 0" class="ma-0">Total controles: {{ exercise.totalEntries }} </p>
                <br v-if="exercise.type !== 0 && exercise.type !== 5" />
                <template v-if="exercise.type !== 0 && exercise.type !== 5">
                  Cambio: <strong class="percentage" v-bind:class="{ 'isRed': isRed(exercise) }">{{
                    exercise.percentageChange }}</strong>
                </template>

                <template v-if="exercise.type === 5">
                  <br>
                  Cambio tiempo promedio: <strong class="percentage" v-bind:class="{ 'isRed': isRed(exercise) }">{{
                    exercise.percentageChangeTime }}</strong><br>
                  Cambio mejor tiempo: <strong class="percentage" v-bind:class="{ 'isRed': isRed(exercise) }">{{
                    exercise.percentageChangeBestTime }}</strong>
                </template>
              </v-card-subtitle>

              <v-card-text>
                <div v-if="exercise.type === 0">
                  <!-- Type 0: Show charts by rep range -->
                  <div v-for="(repData, repIndex) in exercise.repRanges" :key="repIndex">
                    <h3>{{ repData.rep }} RM</h3>
                    <div>Total controles: {{ repData.totalEntries }}</div>
                    <div>Cambio: <strong class="percentage"
                        v-bind:class="{ 'isRed': isRed({ type: exercise.type, ...repData }) }">
                        {{ repData.percentageChange }}</strong></div>
                    <VueApexCharts type="line" :options="chartOptions"
                      :series="[{ name: 'Peso', data: repData.chartData }]" height="250" />
                    <!--  <v-simple-table class="mt-4">
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Peso (kg)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(entry, idx) in repData.entries" :key="idx">
                          <td>{{ formatDate(entry.date, 'DD/MM/YYYY HH:mm') }}</td>
                          <td>{{ entry.weight }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table> -->
                  </div>
                </div>

                <div v-else-if="exercise.type === 5">
                  <!-- Type 5: Two metrics - time & bestTime -->
                  <VueApexCharts v-if="exercise.chartDataTime.length > 0 || exercise.chartDataBestTime.length > 0"
                    type="line" :options="chartOptions" :series="[
                      { name: 'Tiempo promedio', data: exercise.chartDataTime },
                      { name: 'Mejor tiempo', data: exercise.chartDataBestTime }
                    ]" height="250" />

                  <!--  <v-simple-table class="mt-4">
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Tiempo (seg)</th>
                        <th>Mejor Tiempo (seg)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(entry, idx) in exercise.history" :key="idx">
                        <td>{{ formatDate(entry.date, 'DD/MM/YYYY HH:mm') }}</td>
                        <td>{{ entry.time }}</td>
                        <td>{{ entry.bestTime }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table> -->
                </div>

                <div v-else>
                  <!-- Non type 0 and non type 5: Single primary metric -->
                  <VueApexCharts v-if="exercise.chartData && exercise.chartData.length > 0" type="line"
                    :options="chartOptions" :series="[{ name: exercise.metricLabel, data: exercise.chartData }]"
                    height="250" />
                  <!--  <v-simple-table class="mt-4">
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>{{ exercise.metricLabel }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(entry, idx) in exercise.history" :key="idx">
                        <td>{{ formatDate(entry.date, 'DD/MM/YYYY HH:mm') }}</td>
                        <td>{{ entry.value }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table> -->
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { getFirestore, collection, getDocs, query, where, orderBy } from "firebase/firestore";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

export default {
  name: 'ProgressResume',
  components: {
    VueApexCharts
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      exerciseTypes: [
        { value: null, icon: 'mdi-filter-variant', text: 'Todas' },
        { value: 0, icon: 'mdi-weight-kilogram', text: 'RM' },
        { value: 1, icon: 'mdi-counter', text: 'Velocidad' },
        { value: 2, icon: 'mdi-clock-time-four-outline', text: 'Tiempo' },
        { value: 3, icon: 'mdi-ruler', text: 'Distancia' },
        { value: 4, icon: 'mdi-weight-kilogram', text: 'Peso' },
        { value: 5, icon: 'mdi-clock-time-four-outline', text: 'Mejor Tiempo + Promedio' }
      ],
      selectedType: null, // default
      loading: false,
      exercises: [],
      selectedPeriod: 'month', // default
      startDate: null,
      endDate: null,
      chartOptions: {
        chart: {
          foreColor: '#ccc',
          toolbar: { show: false }
        },
        xaxis: {
          type: 'datetime', // Use datetime axis
        },
        yaxis: {},
        stroke: {
          curve: 'smooth',
          width: 2
        },
        grid: {
          borderColor: '#ffffff30'
        },
        colors: ['#2196f3', '#6ee9ff'],
        tooltip: {
          x: {
            format: 'dd MMM yyyy HH:mm'
          }
        }
      },
    };
  },
  async mounted() {
    this.setDateRange();
    await this.fetchData();
  },
  methods: {
    isRed(exercise) {

      console.log(exercise);
      if (exercise.type == 0 && exercise.percentageChange.charAt(0) == '-') {
        return true;
      }

      if (exercise.type == 5 && exercise.percentageChangeTime.charAt(0) == '-') {
        return false;
      }

      if (exercise.type == 5 && exercise.percentageChangeBestTime.charAt(0) == '-') {
        return false;
      }

      return false;



    },


    returnColor(type) {
      const colors = {
        null: "", // Default Grey
        0: "#1E88E5", // Blue
        1: "#43A047", // Green
        2: "#FB8C00", // Orange
        3: "#8E24AA", // Purple
        4: "#F4511E", // Deep Orange
        5: "#3949AB"  // Indigo
      };
      return colors[type] || ""; // Default Grey
    },
    setDateRange() {
      const now = moment();
      if (this.selectedPeriod === 'week') {
        this.startDate = moment().subtract(7, 'days').startOf('day');
        this.endDate = now.endOf('day');
      } else if (this.selectedPeriod === 'month') {
        this.startDate = moment().subtract(1, 'month').startOf('day');
        this.endDate = now.endOf('day');
      } else if (this.selectedPeriod === '3months') {
        this.startDate = moment().subtract(3, 'months').startOf('day');
        this.endDate = now.endOf('day');
      } else if (this.selectedPeriod === 'all') {
        // all time: no constraints
        this.startDate = null;
        this.endDate = null;
      }
    },
    updateDateFilter() {
      this.setDateRange();
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      this.exercises = [];

      let userId = this.$props.user ? this.$props.user.id : this.$props.userId;


      const db = getFirestore();
      const exercisesRef = collection(db, `users/${userId}/exercises`);
      const exercisesSnap = await getDocs(exercisesRef);

      let allExercises = exercisesSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Build queries for all exercises simultaneously
      let promises = allExercises.map(exercise => {
        const entriesRef = collection(db, `users/${this.userId}/exercises/${exercise.id}/entries`);
        let qRef;
        if (this.selectedPeriod !== 'all') {
          qRef = query(entriesRef, where('date', '>=', this.startDate.toDate()), where('date', '<=', this.endDate.toDate()), orderBy('date', 'asc'));
        } else {
          qRef = query(entriesRef, orderBy('date', 'asc'));
        }

        return getDocs(qRef).then(entriesSnap => {
          const entries = entriesSnap.docs.map(d => d.data());
          if (entries.length === 0) return null; // no data in this period

          // Process exercise entries depending on type
          let processed = null;
          if (exercise.type === 0) {
            processed = this.processType0(entries);
          } else if (exercise.type === 5) {
            processed = this.processType5(entries);
          } else {
            processed = this.processOtherTypes(entries, exercise.type);
          }

          if (processed) {
            return { ...exercise, ...processed };
          } else {
            return null;
          }
        });
      });

      // Wait for all promises to complete
      let results = await Promise.all(promises);

      // Filter out null results (exercises with no data in period)
      this.exercises = results.filter(r => r !== null);

      this.loading = false;
    }
    ,

    processType0(entries) {
      let repGroups = {};
      entries.forEach(e => {
        if (e.weight && e.repAmount && e.date && e.date.seconds) {
          let rep = e.repAmount;
          if (!repGroups[rep]) repGroups[rep] = [];
          repGroups[rep].push(e);
        }
      });

      let repRanges = [];
      let totalEntries = 0;
      for (let rep in repGroups) {
        let sortedEntries = repGroups[rep].sort((a, b) => a.date.seconds - b.date.seconds);
        if (sortedEntries.length === 0) continue;

        let chartData = sortedEntries.map(e => {
          const entryDate = new Date(e.date.seconds * 1000);
          return {
            x: entryDate,  // Using a Date object
            y: e.weight
          };
        });

        totalEntries += sortedEntries.length;
        let firstVal = chartData[0].y;
        let lastVal = chartData[chartData.length - 1].y;
        let percChange = this.computePercentageChange(firstVal, lastVal);

        let historyEntries = sortedEntries.map(e => ({
          ...e,
          date: new Date(e.date.seconds * 1000)
        }));

        repRanges.push({
          rep: rep,
          entries: historyEntries,
          totalEntries: sortedEntries.length,
          chartData: chartData,
          percentageChange: percChange
        });
      }

      if (repRanges.length === 0) return null;
      return {
        repRanges: repRanges,
        totalEntries: totalEntries,
        percentageChange: ''
      };
    },

    processType5(entries) {
      let allEntries = entries.filter(e => e.date && e.date.seconds);
      if (allEntries.length === 0) return null;

      allEntries.sort((a, b) => a.date.seconds - b.date.seconds);

      const chartDataTime = allEntries.map(e => {
        const entryDate = new Date(e.date.seconds * 1000);
        return {
          x: entryDate,
          y: e.time !== undefined ? e.time : null
        };
      });

      const chartDataBestTime = allEntries.map(e => {
        const entryDate = new Date(e.date.seconds * 1000);
        return {
          x: entryDate,
          y: e.bestTime !== undefined ? e.bestTime : null
        };
      });

      const validTimeEntries = chartDataTime.filter(d => d.y !== null);
      const validBestTimeEntries = chartDataBestTime.filter(d => d.y !== null);

      let percentageChangeTime = '';
      let percentageChangeBestTime = '';

      if (validTimeEntries.length > 1) {
        let firstTimeVal = validTimeEntries[0].y;
        let lastTimeVal = validTimeEntries[validTimeEntries.length - 1].y;
        percentageChangeTime = this.computePercentageChange(firstTimeVal, lastTimeVal);
      }

      if (validBestTimeEntries.length > 1) {
        let firstBestTimeVal = validBestTimeEntries[0].y;
        let lastBestTimeVal = validBestTimeEntries[validBestTimeEntries.length - 1].y;
        percentageChangeBestTime = this.computePercentageChange(firstBestTimeVal, lastBestTimeVal);
      }

      let history = allEntries.map(e => ({
        date: new Date(e.date.seconds * 1000),
        time: e.time !== undefined ? e.time : '',
        bestTime: e.bestTime !== undefined ? e.bestTime : ''
      }));

      return {
        totalEntries: allEntries.length,
        chartDataTime: chartDataTime,
        chartDataBestTime: chartDataBestTime,
        percentageChangeTime: percentageChangeTime,
        percentageChangeBestTime: percentageChangeBestTime,
        history: history
      };
    },

    processOtherTypes(entries, type) {
      const key = this.getPrimaryMetricKey(type);
      let filtered = entries.filter(e => e[key] !== undefined && e.date && e.date.seconds);
      if (filtered.length === 0) return null;

      filtered.sort((a, b) => a.date.seconds - b.date.seconds);

      let chartData = filtered.map(e => {
        const entryDate = new Date(e.date.seconds * 1000);
        return {
          x: entryDate,
          y: e[key]
        };
      });

      let firstVal = chartData[0].y;
      let lastVal = chartData[chartData.length - 1].y;
      let percChange = this.computePercentageChange(firstVal, lastVal);

      let history = filtered.map(e => ({
        date: new Date(e.date.seconds * 1000),
        value: e[key]
      }));

      return {
        totalEntries: filtered.length,
        chartData: chartData,
        percentageChange: percChange,
        history: history,
        metricLabel: this.getMetricLabel(type)
      };
    },

    computePercentageChange(firstVal, lastVal) {
      if (firstVal === undefined || lastVal === undefined || firstVal === 0) return '';
      let diff = (lastVal - firstVal) / Math.abs(firstVal) * 100;
      let sign = diff >= 0 ? '+' : '';
      return `${sign}${diff.toFixed(1)}%`;
    },

    getPrimaryMetricKey(type) {
      switch (type) {
        case 0: return 'weight';
        case 1: return 'velocity';
        case 2: return 'time';
        case 3: return 'distance';
        case 4: return 'weight';
        case 5: return null;
        default: return null;
      }
    },
    getMetricLabel(type) {
      switch (type) {
        case 1: return 'Velocidad (m/s)';
        case 2: return 'Tiempo (seg)';
        case 3: return 'Distancia (cm)';
        case 4: return 'Peso (kg)';
        default: return 'Valor';
      }
    },
    getExerciseIcon(type) {
      switch (type) {
        case 0: return 'mdi-weight-kilogram';
        case 1: return 'mdi-counter';
        case 2:
        case 5: return 'mdi-clock-time-four-outline';
        case 3: return 'mdi-ruler';
        case 4: return 'mdi-weight-kilogram';
        default: return 'mdi-help-circle';
      }
    },
    formatDate(date, formatStr = 'DD/MM/YYYY') {
      return moment(date).format(formatStr);
    }
  },
  computed: {
    getFilteredExercises() {
      if (this.selectedType === null) return this.exercises;
      return this.exercises.filter(ex => ex.type === this.selectedType);
    },
    filteredExerciseTypes() {
      return this.exerciseTypes.filter(type => type.value !== this.selectedType);
    }
  }
}
</script>

<style>
.percentage {
  color: var(--v-success-base);
}

.percentage.isRed {
  color: var(--v-error-base) !important;
}

.masonry {
  column-count: 2;
  /* Number of columns you want */
  column-gap: 1rem;
}

.masonry-item {
  display: inline-block;
  margin-bottom: 1rem;
  width: 100%;
}

/* Smaller than md breakpoint (usually 960px) */
@media (max-width: 960px) {
  .masonry {
    column-count: 1;
    /* smaller gap on small screens */
  }
}
</style>
