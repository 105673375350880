<template>
  <v-container v-bind:class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
    <v-card class="rounded-lg overflow-hidden" elevation="1">
      <v-card-title>
        <v-icon left>mdi-checkbox-marked-outline</v-icon>
        <span class="headline">Tareas</span>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="openAddTaskDialog">
          <v-icon left>mdi-plus</v-icon>
          Agregar
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">

        <v-tabs grow>
          <v-tab>
            <v-icon left>mdi-circle-outline</v-icon>
            Pendientes
          </v-tab>
          <v-tab>
            <v-icon left color="success">mdi-check-circle</v-icon>
            Completadas
          </v-tab>
          <v-tab-item>

            <v-divider />
            <v-row no-gutters>
              <v-col cols="12" v-if="!loading && tasks.length > 0">
                <v-list class="py-0">
                  <template v-for="task in tasks.filter(task => !task.done)">
                    <v-list-item :key="task.id" three-line @click="openEditTaskDialog(task)">
                      <v-list-item-avatar>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="toggleTaskStatus(task)"
                              :disabled="task.done">
                              <v-icon :color="task.done ? 'green' : 'grey'">
                                {{ task.done ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ task.done ? 'Marcar como pendiente' : 'Marcar como completada' }}
                          </span>
                        </v-tooltip>


                      </v-list-item-avatar>
                      <v-list-item-content>


                        <v-list-item-title>

                          {{ task.title }}

                          <v-icon v-if="isOverdue(task)" color="red" small class="ml-1" title="Tarea vencida">
                            mdi-alert-circle
                          </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div>{{ getFormatSince(task.dueDate) }}</div>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="task.priority">
                          <v-chip :color="getPriorityColor(task.priority)" small dark>
                            {{ task.priority }}
                          </v-chip>
                        </v-list-item-subtitle>



                      </v-list-item-content>
                      <v-list-item-action>

                        <v-btn color="error" icon class="glass" @click="deleteTask(task.id)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider v-if="tasks.indexOf(task) < tasks.length - 1" :key="`divider-${task.id}`" />
                  </template>
                </v-list>
              </v-col>

              <v-col v-if="tasks.filter(task => !task.done).length === 0" cols="12">
                <v-card class="glass" height="350">
                  <v-card-text class="text-center align-content-center fill-height">

                    <template v-if="!loading">
                      <v-icon x-large color="primary">mdi-check-all</v-icon>
                      <div class="headline">¡No hay tareas pendientes!</div>
                    </template>
                    <template v-else>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-divider />

            <v-row>
              <v-col cols="12" v-if="!loading && tasks.filter(task => task.done).length > 0">
                <v-list class="py-0">
                  <template v-for="task in tasks.filter(task => task.done)">
                    <v-list-item :key="task.id" three-line @click="openEditTaskDialog(task)">
                      <v-list-item-avatar>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click.stop="toggleTaskStatus(task)"
                              :disabled="!task.done">
                              <v-icon :color="task.done ? 'green' : 'grey'">
                                {{ task.done ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ task.done ? 'Marcar como pendiente' : 'Marcar como completada' }}
                          </span>
                        </v-tooltip>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ task.title }}
                          <v-icon v-if="isOverdue(task)" color="red" small class="ml-1" title="Tarea vencida">
                            mdi-alert-circle
                          </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <div>{{ getFormatSince(task.dueDate) }}</div>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="task.priority">
                          <v-chip :color="getPriorityColor(task.priority)" small dark>
                            {{ task.priority }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn color="error" icon class="glass" @click="deleteTask(task.id)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="tasks.indexOf(task) < tasks.length - 1" :key="`divider-${task.id}`" />
                  </template>
                </v-list>
              </v-col>

              <v-col v-if="tasks.filter(task => task.done).length === 0" cols="12">
                <v-card class="glass" height="350">
                  <v-card-text class="text-center align-content-center fill-height">


                    <template v-if="!loading">
                      <div class="headline">¡No hay tareas completadas!</div>

                    </template>
                    <template v-else>
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>


            </v-row>
          </v-tab-item>



        </v-tabs>





        <!-- Task Data Table -->
        <!--     <v-row>
          <v-col v-for="task in tasks" :key="task.id" cols="12" md="6" lg="4">
            <v-card  class="mb-2 glass">
              <v-card-title>
                <v-chip :color="getPriorityColor(task.priority)" small dark>
                  {{ task.priority }}
                </v-chip>
                <v-spacer></v-spacer>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon @click="toggleTaskStatus(task)" v-on="on">
                  <v-icon :color="task.done ? 'green' : 'grey'">
                    {{ task.done ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline' }}
                  </v-icon>
                </v-btn>
                  </template>
                  <span>
                    {{ task.done ? 'Marcar como pendiente' : 'Marcar como completada' }}
                  </span>
                </v-tooltip>


                
              </v-card-title>
              <v-card-subtitle>
                <span>{{ task.title }}</span>
                <v-icon v-if="isOverdue(task)" color="red" small class="ml-1" title="Tarea vencida">
                  mdi-alert-circle
                </v-icon>
              </v-card-subtitle>
              <v-card-text>
                <div>{{ formatDate(task.dueDate) }}</div>
              </v-card-text>
              <v-card-actions>
                  <v-btn left @click="deleteTask(task.id)" class="glass">
                  <v-icon >mdi-delete</v-icon>
                
                </v-btn>
               
                <v-spacer></v-spacer>
               <v-btn  @click="openEditTaskDialog(task)" class="glass">
                  <v-icon left>mdi-pencil</v-icon>
                  editar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>

    <!-- Add/Edit Task Dialog -->
    <v-navigation-drawer v-model="taskDialog" width="900" app right temporary class="overflow-y-scroll"
      style="z-index:100">
      <v-card v-bind:class="{ 'mb-12': $vuetify.breakpoint.smAndDown }">
        <v-card-title class="headline">

          <v-btn icon text @click="closeTaskDialog" class="mr-2">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          {{ editMode ? 'Editar Tarea' : 'Agregar Tarea' }}

          <v-spacer></v-spacer>

          <v-btn color="success" @click="saveTask" :loading="loadingSave">
            <v-icon left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-form ref="taskForm" @submit.prevent="saveTask">
            <v-text-field filled rounded class="rounded-lg" v-model="taskForm.title" :rules="[rules.required]"
              label="Título" required></v-text-field>
            <!--  <v-textarea filled rounded class="rounded-lg"
              v-model="taskForm.description"
              label="Descripción"
              auto-grow
              rows="2"
            ></v-textarea> -->

            <WYSIWYGeditorVue ref="editor" v-if="taskDialog" v-model="taskForm.description" />



            <v-menu ref="dueDateMenu" v-model="dueDateMenu" :close-on-content-click="false"
              transition="scale-transition" offset-y max-width="290px" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field filled rounded class="rounded-lg" v-model="formattedDueDate" label="Fecha de vencimiento"
                  prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="taskForm.dueDate" @input="dueDateMenu = false"></v-date-picker>
            </v-menu>
            <v-select filled rounded class="rounded-lg" v-model="taskForm.priority" :items="priorityLevels"
              label="Prioridad" required></v-select>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-navigation-drawer>


  </v-container>
</template>

<script>
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,

  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import WYSIWYGeditorVue from '@/components/editor/WYSIWYGeditor.vue';
import moment from 'moment';

export default {
  components: {
    WYSIWYGeditorVue
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Campo requerido',
      },
      content: null,
      tasks: [],
      loading: true,
      headers: [
        { text: 'Estado', value: 'done', align: 'center' },
        { text: 'Prioridad', value: 'priority', align: 'center' },
        { text: 'Título', value: 'title', align: 'start' },
        { text: 'Fecha de Vencimiento', value: 'dueDate', align: 'center' },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' },
      ],
      priorityLevels: ["Alta", "Media", "Baja"],
      taskDialog: false,
      subtasksDialog: false,
      subtaskDialog: false,
      dueDateMenu: false,
      editMode: false,
      taskForm: {
        id: null,
        title: '',
        description: '',
        dueDate: null,
        priority: null,
        subtasks: [],
        done: false,
      },
      selectedTask: null,
      newSubtaskText: '',
      subtaskForm: {
        index: null,
        text: '',
      },
      loadingSave: false,
    };
  },

  computed: {
    formattedDueDate() {
      return this.taskForm.dueDate
        ? this.formatDate(this.taskForm.dueDate)
        : '';
    },
  },

  methods: {
    openAddTaskDialog() {
      this.editMode = false;
      this.taskForm = {
        id: null,
        title: '',
        description: '',
        dueDate: null,
        priority: null,
        subtasks: [],
        done: false,
      };
      this.taskDialog = true;
    },
    openEditTaskDialog(task) {


      this.editMode = true;
      this.taskForm = { ...task };
      if (this.taskForm.dueDate && this.taskForm.dueDate.toDate) {
        this.taskForm.dueDate = this.taskForm.dueDate.toDate();
      }



      this.taskDialog = true;

      this.$nextTick(() => {
        this.$refs.editor.update(this.taskForm.description)
      });


    },
    closeTaskDialog() {
      this.taskDialog = false;
    },
    async saveTask() {
      if (

        !this.$refs.taskForm.validate() ||
        this.loadingSave
      ) return;


      const editor = this.$refs.editor

      // Delete unused images before saving
      await editor.deleteUnusedImages()


      const db = getFirestore();
      if (this.editMode) {
        // Update existing task
        const taskRef = doc(db, "tasks", this.taskForm.id);
        try {
          this.loadingSave = true;
          await updateDoc(taskRef, {
            title: this.taskForm.title,
            description: this.taskForm.description,
            dueDate: this.taskForm.dueDate || null,
            priority: this.taskForm.priority || null,
            done: this.taskForm.done,
          });
          this.closeTaskDialog();

          this.$notify({
            group: 'feedback',
            type: 'success',
            title: 'Tarea actualizada',
            text: 'La tarea se ha actualizado correctamente',
          });
        } catch (error) {
          console.error("Error updating document: ", error);
        } finally {
          this.loadingSave = false;
        }
      } else {
        // Add new task
        try {
          this.loadingSave = true;
          await addDoc(collection(db, "tasks"), {
            title: this.taskForm.title,
            description: this.taskForm.description,
            done: false,
            date: new Date(),
            dueDate: this.taskForm.dueDate
              ? new Date(this.taskForm.dueDate)
              : null,
            priority: this.taskForm.priority,
            assignees: [],
            createdBy: this.$store.state.Auth.token.claims.user_id,
            completedBy: null,
            subtasks: [],
          });
          this.closeTaskDialog();
          this.$notify({
            group: 'feedback',
            type: 'success',
            title: 'Tarea agregada',
            text: 'La tarea se ha agregado correctamente',
          });

        } catch (error) {
          console.error("Error adding document: ", error);
        } finally {
          this.loadingSave = false;
        }
      }
    },

    async toggleTaskStatus(task) {
      const db = getFirestore();
      const taskRef = doc(db, "tasks", task.id);
      try {
        await updateDoc(taskRef, {
          done: !task.done,
          completedBy: !task.done
            ? this.$store.state.Auth.token.claims.user_id
            : null,
          completedDate: !task.done ? new Date() : null,
          timezoneOffset: new Date().getTimezoneOffset(),
        });

        this.$notify({
          group: 'feedback',
          type: 'success',
          title: 'Tarea actualizada',
          text: `La tarea "${task.title}" se ha marcado como ${task.done ? 'pendiente' : 'completada'
            }`,
        });
      } catch (error) {
        console.error("Error updating task status: ", error);
      }
    },
    async deleteTask(id) {
      const confirm = await this.$confirm(
        "¿Realmente quieres eliminar esta tarea?",
        {
          color: "error",
          title: "Borrar Tarea",
          buttonTrueText: "CONFIRMAR",
          buttonFalseText: "CANCELAR",
        }
      );

      if (!confirm) return;

      await this.$refs.editor.deleteImagesFromHTML(this.taskForm.description);

      const db = getFirestore();
      const taskRef = doc(db, "tasks", id);
      try {
        await deleteDoc(taskRef);
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    },
    isOverdue(task) {
      if (!task.dueDate || task.done) return false;
      const dueDate = task.dueDate.toDate
        ? task.dueDate.toDate()
        : new Date(task.dueDate);
      const now = new Date();
      return dueDate < now;
    },
    formatDate(date) {
      if (!date) return "Sin vencimiento";
      const dueDate = date.toDate ? date.toDate() : new Date(date);
      return dueDate.toLocaleDateString();
    },
    getPriorityColor(priority) {
      switch (priority) {
        case "Alta":
          return "red";
        case "Media":
          return "orange";
        case "Baja":
          return "green";
        default:
          return "grey";
      }
    },
    getFormatSince(date) {
      if (!date) return "Sin vencimiento";

      return 'Vence ' + moment(date).locale('es').fromNow();
    },
  },

  mounted() {



    const db = getFirestore();
    const q = query(
      collection(db, "tasks"),
      where("createdBy", "==", this.$store.state.Auth.token.claims.user_id));
    onSnapshot(q, (querySnapshot) => {
      this.tasks = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Convert Firestore Timestamp to JS Date
      this.tasks.forEach((task) => {
        if (task.dueDate && task.dueDate.toDate) {
          task.dueDate = task.dueDate.toDate();
        }
      });

      // Sort tasks by due date
      this.tasks.sort((a, b) => b.dueDate - a.dueDate);

      this.loading = false;
    });
  },
};
</script>
