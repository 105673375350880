import { collection, query, where, getDocs, getFirestore, getCountFromServer, getAggregateFromServer, sum } from 'firebase/firestore'
import moment from 'moment'

export default class DashboardService {
    static async getDashboardData() {
        const db = getFirestore()
        
        // Date ranges
        const currentStart = moment().startOf('month').toDate()
        const currentEnd = moment().endOf('month').toDate()
        const previousStart = moment().subtract(1, 'month').startOf('month').toDate()
        const previousEnd = moment().subtract(1, 'month').endOf('month').toDate()

        try {
            // Batch all queries together
            const [
                currentTotalUsers,
                previousTotalUsers,
                newUsersCurrentMonth,
                newUsersPreviousMonth,
                disabledCurrentMonth,
                disabledPreviousMonth,
                reEnabledCurrentMonth,
                reEnabledPreviousMonth,
                assistancesCurrentMonth,
                assistancesPreviousMonth,
                currentMonthMargins,
                previousMonthMargins
            ] = await Promise.all([
                // Current total active users
                getCountFromServer(query(
                    collection(db, 'users'),
                    where('disabled', '==', false),
                    where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online'])
                )),

                // Previous month total active users
                getCountFromServer(query(
                    collection(db, 'users'),
                    where('disabled', '==', false),
                    where('createdAt', '<=', previousEnd),
                    where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online'])
                )),
                
                // New users current month
                getCountFromServer(query(
                    collection(db, 'users'),
                    where('disabled', '==', false),
                    where('createdAt', '>=', currentStart),
                    where('createdAt', '<=', currentEnd),
                    where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online'])
                )),

                // New users previous month
                getCountFromServer(query(
                    collection(db, 'users'),
                    where('disabled', '==', false),
                    where('createdAt', '>=', previousStart),
                    where('createdAt', '<=', previousEnd),
                    where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online'])
                )),

                // Disabled users current month
                getCountFromServer(query(
                    collection(db, 'disabledUsers'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd)
                )),

                // Disabled users previous month
                getCountFromServer(query(
                    collection(db, 'disabledUsers'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd)
                )),

                // Re-enabled users current month
                getCountFromServer(query(
                    collection(db, 'reEnabledUsers'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd)
                )),

                // Re-enabled users previous month
                getCountFromServer(query(
                    collection(db, 'reEnabledUsers'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd)
                )),

                // Assistances current month
                getCountFromServer(query(
                    collection(db, 'workouts'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd)
                )),

                // Assistances previous month
                getCountFromServer(query(
                    collection(db, 'workouts'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd)
                )),

                // Replace fetchMarginsData with direct aggregate queries
                this.fetchMonthMargins(db, currentStart, currentEnd),
                this.fetchMonthMargins(db, previousStart, previousEnd)
            ])

            return {
                totalUsers: {
                    current: currentTotalUsers.data().count,
                    previous: previousTotalUsers.data().count
                },
                newUsers: {
                    current: newUsersCurrentMonth.data().count,
                    previous: newUsersPreviousMonth.data().count
                },
                disabledUsers: {
                    current: disabledCurrentMonth.data().count,
                    previous: disabledPreviousMonth.data().count
                },
                reEnabledUsers: {
                    current: reEnabledCurrentMonth.data().count,
                    previous: reEnabledPreviousMonth.data().count
                },
                assistances: {
                    current: assistancesCurrentMonth.data().count,
                    previous: assistancesPreviousMonth.data().count
                },
                margins: {
                    current: currentMonthMargins,
                    previous: previousMonthMargins
                }
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error)
            throw error
        }
    }

    static async fetchMonthMargins(db, startDate, endDate) {
        // Get payments sum (ingress)
        const paymentsQuery = query(
            collection(db, 'payments'),
            where('createdAt', '>=', startDate),
            where('createdAt', '<=', endDate)
        )
        
        // Get expenses sum (egress)
        const expensesQuery = query(
            collection(db, 'ingress-egress'),
            where('date', '>=', startDate),
            where('date', '<=', endDate),
            where('type', '==', 'egress')
        )

        // Get other income sum (ingress)
        const ingressQuery = query(
            collection(db, 'ingress-egress'),
            where('date', '>=', startDate),
            where('date', '<=', endDate),
            where('type', '==', 'ingress')
        )

        const [paymentsSum, expensesSum, ingressSum] = await Promise.all([
            getAggregateFromServer(paymentsQuery, {
                totalAmount: sum('total')
            }),
            getAggregateFromServer(expensesQuery, {
                totalAmount: sum('payAmount')
            }),
            getAggregateFromServer(ingressQuery, {
                totalAmount: sum('payAmount')
            })
        ])

        const totalIngress = (paymentsSum.data().totalAmount || 0) + (ingressSum.data().totalAmount || 0)
        const totalEgress = expensesSum.data().totalAmount || 0

        return totalIngress - totalEgress
    }
} 