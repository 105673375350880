<template>
  <v-container class="pt-4">
    <v-card class="mb-4" rounded="lg">
      <v-card-title>
        <v-icon left color="primary">
          mdi-chart-timeline-variant
        </v-icon>
        Actividad Reciente
      </v-card-title>
      <v-divider />

      <v-card-title>
        <v-spacer />
        <v-btn-toggle v-model="selectedRange" dense mandatory rounded>
          <v-btn value="1M" large>1M</v-btn>
          <v-btn value="3M" large>3M</v-btn>
          <v-btn value="6M" large>6M</v-btn>
          <v-btn value="TODO" large>TODO</v-btn>
        </v-btn-toggle>
        <v-spacer />
      </v-card-title>

      <v-card-text>
        <v-row>
          <!-- Chart -->
          <v-col cols="12">
            <v-card id="skelet" class=" overflow-hidden" height="250" v-if="!neverAssisted">
              <apexchart height="250px" v-if="!loading" type="bar" :options="chartOptions" :series="series"></apexchart>
              <v-skeleton-loader v-else type="image"></v-skeleton-loader>
            </v-card>
          </v-col>


          <v-img v-if="!loading && neverAssisted" src="@/assets/alert.svg" height="200px" class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7)">

          </v-img>
          <v-alert v-if="neverAssisted" type="info" text>
            Registra tu primera asistencia para ver estadísticas.
          </v-alert>



        </v-row>

      </v-card-text>
    </v-card>

    <v-card class="mb-4" rounded="lg">
      <v-card-title>
        <v-icon left color="primary">
          mdi-calendar-check
        </v-icon>
        Estadísticas de asistencias
      </v-card-title>

      <v-divider />
      <v-card-text class="text-center">
        <v-row>
          <v-col cols="4">
            <div>
              <p class="mb-2">
                <strong>Este mes</strong>
              </p>

              <p class="headline  mb-1">
                <strong> {{ counts.thisMonth }}</strong>
              </p>

              <p>
                asistencias
              </p>
            </div>
          </v-col>
          <v-col cols="4">
            <div>
              <p class="mb-2">
                <strong>Este año</strong>
              </p>

              <p class="headline mb-1">
                <strong>{{ counts.thisYear }}</strong>
              </p>

              <p>
                asistencias
              </p>
            </div>




          </v-col>
          <v-col cols="4">
            <div>
              <p class="mb-2">
                <strong>Total</strong>
              </p>

              <p class="headline  mb-1">
                <strong>{{ counts.total }}</strong>
              </p>

              <p>
                asistencias
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-card class="overflow-hidden" rounded="lg">
      <v-card-title>
        <v-icon left color="primary">
          mdi-calendar-month
        </v-icon>
        Calendario de asistencias
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-date-picker v-model="selectedDate" width="100%" :events="events" event-color="success" locale="es" readonly
          class="elevation-0">
        </v-date-picker>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import 'moment/locale/es'; // Import Spanish locale
import { getFirestore, collection, getDocs, orderBy, query, where } from 'firebase/firestore';

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      events: [],
      loading: true,
      selectedRange: '1M',
      chartOptions: {
        chart: {
          id: 'asistencias-chart',
          height: 280,
          toolbar: {
            show: false,
          },
          fontFamily: 'Roboto, sans-serif',
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            columnWidth: '60%',
          }
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return Math.floor(val);
            },
          },
        },
        grid: {
          borderColor: '#f5f5f5',
          strokeDashArray: 4,
        },
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
      },
      series: [
        {
          name: 'Asistencias',
          data: [],
        },
      ],
      counts: {
        thisMonth: 0,
        thisYear: 0,
        total: 0,
      },
      allAssistances: [], // All assistances fetched from Firestore,
      neverAssisted: false,
      ranges: [
        { value: '1M', text: '1M' },
        { value: '3M', text: '3M' },
        { value: '6M', text: '6M' },
        { value: 'TODO', text: 'TODO' }
      ],
      statistics: [
        { title: 'Este mes', value: 'thisMonth' },
        { title: 'Este año', value: 'thisYear' },
        { title: 'Total', value: 'total' }
      ],
      selectedDate: new Date().toISOString().substr(0, 10),
    };
  },
  watch: {
    selectedRange() {
      this.loading = true;

      this.$nextTick(() => {
        this.updateChart();

      });
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
    moment.locale('es'); // Set moment locale to Spanish
    this.fetchAssistances();
  },
  computed: {
    cardClass() {
      return {
        'elevation-0': true,
        'rounded-lg': true,
        'grey lighten-5': !this.$store.state.isDark,
        'grey darken-4': this.$store.state.isDark
      }
    },
    textClass() {
      return {
        'text-subtitle-2': true,
        'text-medium-emphasis': !this.$store.state.isDark,
        'text-medium-emphasis--text': this.$store.state.isDark,
        'mb-1': true
      }
    }
  },
  methods: {
    getEventColor(event) {
      return event.color || '#1976D2';
    },
    async fetchAssistances() {
      const db = getFirestore();
      const userid = this.$store.state.Auth.token.claims.user_id;
      const workoutsRef = collection(db, `users/${userid}/workouts`);

      // Fetch all workouts LEGACY
      const querySnapshot = await getDocs(workoutsRef);
      const assistances = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.dates.forEach((timestamp) => {
          const dateObj = moment(timestamp, "YYYY-MM-DD HH:mm:ss");
          assistances.push(dateObj);

          // Add event to calendar
          this.events.push(dateObj.format('YYYY-MM-DD'));

        });
      });


      // Fetch all workouts NEW
      const workoutsRefNew = collection(db, `workouts`);
      const q = query(workoutsRefNew, where("user_id", "==", userid));
      const workoutDocsNew = await getDocs(q);
      const assistancesNew = workoutDocsNew.docs.map(doc => moment(new Date(doc.data().date.seconds * 1000)));

      assistancesNew.forEach(date => {
        assistances.push(date.format('YYYY-MM-DD HH:mm:ss'))
      })


      if (assistances.length === 0) {
        this.neverAssisted = true;
      }

      this.allAssistances = assistances;

      this.updateCounts();
      this.updateChart();
    },
    updateChart() {
      this.loading = true;
      let filteredAssistances = [];

      switch (this.selectedRange) {
        case '1M':
          filteredAssistances = this.allAssistances.filter((date) =>
            date.isSameOrAfter(moment().subtract(1, 'months').startOf('day'))
          );
          // If no data in 1M view, switch to 'TODO'
          if (filteredAssistances.length === 0) {
            this.selectedRange = 'TODO';
            filteredAssistances = this.allAssistances;
          }
          break;
        case '3M':
          filteredAssistances = this.allAssistances.filter((date) =>
            date.isSameOrAfter(moment().subtract(3, 'months').startOf('day'))
          );
          break;
        case '6M':
          filteredAssistances = this.allAssistances.filter((date) =>
            date.isSameOrAfter(moment().subtract(6, 'months').startOf('day'))
          );
          break;
        case '1A':
          filteredAssistances = this.allAssistances.filter((date) =>
            date.isSameOrAfter(moment().subtract(1, 'years').startOf('day'))
          );
          break;
        case 'TODO':
          filteredAssistances = this.allAssistances;
          break;
      }

      if (this.selectedRange === '1M') {
        // Group by weekdays in Spanish
        const weekdays = ['lun', 'mar', 'mié', 'jue', 'vie', 'sáb', 'dom'];
        const data = [0, 0, 0, 0, 0, 0, 0];

        filteredAssistances.forEach((date) => {
          const dayOfWeek = date.isoWeekday(); // 1 (Monday) to 7 (Sunday)
          data[dayOfWeek - 1]++;
        });

        this.chartOptions.xaxis.categories = weekdays;
        this.series = [
          {
            name: 'Asistencias',
            data: data,
          },
        ];
      } else {
        // Group by month
        const monthCounts = {};

        filteredAssistances.forEach((date) => {
          let month = date.locale("es").format('MMM');
          month = month.replace('.', ''); // Remove dot
          if (!monthCounts[month]) {
            monthCounts[month] = 0;
          }
          monthCounts[month]++;
        });

        // Get months in order
        const monthsOrder = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
        const categories = monthsOrder.filter((month) => monthCounts[month]);
        const data = categories.map((month) => monthCounts[month]);

        this.chartOptions.xaxis.categories = categories;
        this.series = [
          {
            name: 'Asistencias',
            data: data,
          },
        ];

      }

      this.loading = false;
    },
    updateCounts() {
      const now = moment();
      this.counts.total = this.allAssistances.length;
      this.counts.thisMonth = this.allAssistances.filter((date) => date.isSame(now, 'month')).length;
      this.counts.thisYear = this.allAssistances.filter((date) => date.isSame(now, 'year')).length;
    },
  },
};
</script>


<style scoped>
.v-btn-toggle .v-btn {
  text-transform: none !important;
  letter-spacing: normal !important;
  border: none !important;
}



/* Optional: Add smooth transitions */
.v-card {
  transition: all 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
}
</style>
