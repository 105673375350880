<template>
  <v-row :class="['background', $vuetify.theme.dark ? 'background--dark' : '']" align="center" justify="center"
    style="height:100vh" no-gutters>
    <div class="login-container">
      <v-card class="login-card" elevation="10">
        <!-- Logo section -->
        <v-row no-gutters>
          <v-col cols="12" class="text-center pb-4 d-flex justify-center">
            <v-img alt="Sportfit Logo" class="shrink mt-8 mb-6 logo-animation" contain @click="$router.replace('/')"
              :src="$store.state.isDark ? require('../assets/logo_y_slogan_blanco.png') : require('../assets/logo_y_slogan.png')"
              transition="scale-transition" width="180" />
          </v-col>

          <v-col cols="12" class="px-12">
            <v-form ref="login" lazy-validation>
              <div class="text-h5 mb-8 font-weight-medium text-center primary--text">
                Ingresa a tu cuenta
              </div>

              <v-text-field prepend-inner-icon="mdi-email" :rules="[rules.required]" label="Email" filled rounded dense
                color="primary" v-model="username" @keyup.enter="doLogin()" class="mb-4 input-field"
                :class="{ 'input-field--dark': $vuetify.theme.dark }"></v-text-field>

              <v-text-field prepend-inner-icon="mdi-lock" :label="$i18n.t('LOGIN.passwordLabel')"
                :rules="[rules.required]" v-model="password" filled rounded dense color="primary"
                :type="showPassword ? 'password' : 'text'" @keyup.enter="doLogin()" class="input-field"
                :class="{ 'input-field--dark': $vuetify.theme.dark }">
                <template v-slot:append>
                  <v-icon @click="togglePasswords" class="password-toggle">
                    {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </template>
              </v-text-field>
            </v-form>

            <!-- Error Alert -->
            <v-scale-transition>
              <v-alert v-if="error" type="error" border="left" colored-border dense class="mt-4 error-alert"
                elevation="2">
                Si el problema persiste, contacte al administrador.
                <v-btn rounded class="mt-3 whatsapp-btn" color="success" dark block
                  href="https://wa.me/+59898996900?text=Hola!%20Ocurrio%20un%20problema%20al%20iniciar%20sesion%20en%20la%20app"
                  target="_blank">
                  <v-icon left>mdi-whatsapp</v-icon>
                  Whatsapp
                </v-btn>
              </v-alert>
            </v-scale-transition>

            <!-- Forgot Password Link -->
            <div class="mt-4 mb-8 text-center">
              <span @click="showForgetPasswordModal = true" class="forgot-password-link">
                {{ $i18n.t("LOGIN.forgotPassword") }}
              </span>
            </div>

            <!-- Login Button -->
            <v-btn :loading="loadingButton" block x-large color="primary" dark rounded elevation="2" height="50"
              @click.prevent="doLogin" class="login-btn">
              <v-icon left>mdi-login</v-icon>
              <span class="font-weight-medium">{{ $i18n.t("LOGIN.signIn") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- Password Reset Dialog -->
    <v-dialog v-model="showForgetPasswordModal" width="450px"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'" overlay-opacity="0.8" transition="dialog-transition">
      <v-card class="password-reset__content pa-0 rounded-lg overflow-hidden">
        <v-card-title class=" text-h6 pb-4 primary--text">
          {{ $i18n.t("LOGIN.resetPassword") }}
        </v-card-title>
        <v-card-text class=" pb-0">
          <p class="subtitle-1 grey--text text--darken-1">
            Ingresa tu email para recibir un link de recuperación de contraseña.
          </p>
          <v-form ref="forgetPasswordForm" lazy-validation class="mt-6">
            <v-text-field outlined dense prepend-inner-icon="mdi-email" label="Email" v-model="forgetEmail"
              :rules="[rules.required]" class="reset-input"></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="pt-4 px-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn text @click="showForgetPasswordModal = false" class="mr-4 text-capitalize">
            {{ $i18n.t("GENERAL.close") }}
          </v-btn>
          <v-btn :loading="loadingForgetButton" color="primary" @click="forgetPasswordRequest" elevation="2"
            class="px-6 text-capitalize">
            {{ $i18n.t("GENERAL.send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import firebase from "./../../firebase.js";

/* function whichAuth() {
  let auth
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence
    })
  } else {
    auth = getAuth()
  }
  return auth
}
 */

//import firebase from 'firebase/compat/app';

import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Capacitor } from '@capacitor/core';
import { getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth';
export default {

  mounted() {
    this.$vuetify.goTo(0)
    //this.startFirebaseUi();
  },

  data() {
    return {

      showPassword: true,
      username: "",
      password: "",
      showForgetPasswordModal: false,
      loadingButton: false,
      forgetEmail: "",
      forgetUsername: "",
      loadingForgetButton: false,
      rules: {
        required: (value) => !!value || "Este campo es requerido",
      },
      error: false
    }
  },

  methods: {
    togglePasswords() {
      this.showPassword = !this.showPassword;
    },
    startFirebaseUi() {
      var uiConfig = {
        signInSuccessUrl: '/',
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        tosUrl: '/',
        privacyPolicyUrl: '/'
      };
      var ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', uiConfig);

    },


    async forgetPasswordRequest() {
      if (this.$refs.forgetPasswordForm.validate()) {
        try {
          this.loadingForgetButton = true;

          const auth = firebase.auth

          let result = await sendPasswordResetEmail(auth, this.forgetEmail)

          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "Email enviado",
            text: "Revisa tu bandeja de entrada",
          });
        } catch (error) {
          if (error.code == "auth/invalid-email") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Email invalido",
            });
          }

          if (error.code == "auth/user-not-found") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Usuario no encontrado",
            });
          }

          if (error.code == "auth/invalid-credential") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Credenciales invalidas",
            });
          }

          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "Error",
            text: "No se pudo enviar el email de recuperación",
          });
        }
        this.loadingForgetButton = false;
        this.showForgetPasswordModal = false;
      }
    },
    async doLogin() {
      if (this.$refs.login.validate()) {
        try {
          this.loadingButton = true;


          const auth = firebase.auth
          // Sign in
          const userCredential = await signInWithEmailAndPassword(auth, this.username.toLowerCase(), this.password);


          const user = userCredential.user;

          this.error = false;
          this.$vuetify.goTo(0);
        } catch (error) {
          let errorCode = error.code;

          this.error = true;
          if (errorCode == "auth/user-not-found") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Usuario no encontrado",
            });
          }

          if (errorCode == "auth/wrong-password") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Contraseña incorrecta",
            });
          }

          if (errorCode == "auth/invalid-email") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Email invalido",
            });
          }

          if (errorCode == "auth/user-disabled") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Usuario deshabilitado, contacte al administrador",
            });
          }

          if (errorCode == "auth/too-many-requests") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Demasiados intentos, intente mas tarde",
            });
          }

          if (errorCode == "auth/network-request-failed") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Error de red",
            });
          }

          if (errorCode == "auth/operation-not-allowed") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Operacion no permitida",
            });
          }

          if (errorCode == "auth/internal-error") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Error interno",
            });
          }

          if (errorCode == "auth/invalid-credential") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Credenciales invalidas",
            });
          }

          if (errorCode == "auth/invalid-verification-code") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Codigo de verificacion invalido",
            });
          }

          if (errorCode == "auth/invalid-verification-id") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Id de verificacion invalido",
            });
          }

        }
        this.loadingButton = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.background {
  background: linear-gradient(135deg, #0ab1ec 0%, #0892c3 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.05;
  }

  &--dark {
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);

    &::before {
      opacity: 0.1;
    }
  }
}

.login-container {
  position: relative;
  max-width: 500px;
  width: 100%;
  margin: 0 20px;
  z-index: 1;
}

.login-card {
  border-radius: 24px;
  padding: 20px 0;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
}

.logo-animation {
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.input-field {
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }

  &--dark {
    :deep(.v-input__slot) {
      background: rgba(255, 255, 255, 0.05) !important;
    }
  }
}

.forgot-password-link {
  cursor: pointer;
  color: var(--v-primary-base);
  font-size: 0.9rem;
  transition: all 0.3s ease;
  padding: 8px 16px;
  border-radius: 20px;

  &:hover {
    color: var(--v-primary-darken1);
    background: rgba(10, 177, 236, 0.1);
  }
}

.password-toggle {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
}

.login-btn {
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(10, 177, 236, 0.3) !important;
  }
}

.password-reset__content {
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

.whatsapp-btn {
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(37, 211, 102, 0.3) !important;
  }
}

.error-alert {
  border-radius: 12px;
}

.reset-input {
  :deep(.v-input__slot) {
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
    }
  }
}

// Dark theme adjustments
:deep(.v-application.theme--dark) {
  .login-card {
    background-color: rgba(30, 30, 30, 0.95);
    backdrop-filter: blur(20px);
  }

  .password-reset__content {
    background-color: #1E1E1E;
  }
}
</style>
