<template>
    <v-container class="pa-0 mb-12">
        <!-- List of Reports -->
        <v-row no-gutters>


            <v-col cols="3" v-if="!$vuetify.breakpoint.smAndDown && selectedReport == null">

                <v-list>
                    <v-list-group v-for="(group, groupName) in groupedReports" :key="groupName">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>{{
                                    groupIconHash[groupName] || "mdi-file-document-outline"
                                }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ groupName }}</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-for="report in group" :key="report.id" @click="selectReport(report)">

                            <v-list-item-content>
                                <v-list-item-title>- {{ report.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-col>

            <v-col>
                <v-list v-if="$vuetify.breakpoint.smAndDown && selectedReport == null">
                    <v-list-group v-for="(group, groupName) in groupedReports" :key="groupName">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon>{{
                                    groupIconHash[groupName] || "mdi-file-document-outline"
                                }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ groupName }}</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item v-for="report in group" :key="report.id" @click="selectReport(report)">

                            <v-list-item-content>
                                <v-list-item-title>- {{ report.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>

                <!-- Report Details -->
                <v-card v-if="selectedReport">
                    <v-card-title>

                        <v-btn icon @click="selectedReport = null">
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>

                        <v-spacer></v-spacer>

                        {{ selectedReport.title }}

                        <v-spacer></v-spacer>

                        <v-icon style="visibility: hidden;">mdi-alert</v-icon>


                    </v-card-title>





                    <template v-if="selectedReport.title == 'Alertas por dia y gestion'">
                        <Alerts></Alerts>
                    </template>

                    <template v-if="selectedReport.title == 'Confirmaciones de pago'">
                        <Payments></Payments>
                    </template>

                    <template v-if="selectedReport.title == 'Nuevos, Bajas y Renovaciones'">


                        <NewSubscriptions></NewSubscriptions>
                    </template>



                    <template v-if="selectedReport.title == 'Encuesta de satisfacción'">
                        <SatisfactionSurvey></SatisfactionSurvey>
                    </template>

                    <template v-if="selectedReport.title == 'Asistencias por tipo'">
                        <AssistancesPerUsertypeVue></AssistancesPerUsertypeVue>
                    </template>

                    <template v-if="selectedReport.title == 'Asistencias por horario'">
                        <ScheduleStats></ScheduleStats>
                    </template>

                    <template v-if="selectedReport.title == 'Información de usuarios'">
                        <UsersAge></UsersAge>
                    </template>

                    <template v-if="selectedReport.title == 'Usuarios en riesgo'">
                        <UsersInRisk></UsersInRisk>
                    </template>

                    <template v-if="selectedReport.title == 'Resumen financiero'">
                        <FinancialSummary></FinancialSummary>
                    </template>

                    <template v-if="selectedReport.title == 'Transiciones Socio->PaseLibre'">
                        <UserPaselibreTransitions></UserPaselibreTransitions>
                    </template>

                    <template v-if="selectedReport.title == 'Ranking de usuarios'">
                        <UsersRankingVue></UsersRankingVue>
                    </template>

                    <template v-if="selectedReport.title == 'Activos por dia'">
                        <ActiveUsersHistory></ActiveUsersHistory>
                    </template>

                    <template v-if="selectedReport.title == 'Dashboard'">
                        <AdminDashboard></AdminDashboard>
                    </template>




                    <!--                 <v-card-text>{{ selectedReport.content }}</v-card-text>
 --> </v-card>
                <template v-else>
                    <v-card class="ma-4">
                        <v-card-title>Reportes</v-card-title>
                        <v-card-text>Selecciona para ver los detalles</v-card-text>
                    </v-card>
                </template>

            </v-col>


        </v-row>




    </v-container>
</template>

<script>
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Alerts from "@/views/Alerts.vue";
import Payments from "@/views/Payments.vue";


import NewSubscriptions from "@/components/reports/NewSubscriptions.vue";
import SatisfactionSurvey from "@/components/reports/SatisfactionSurvey.vue";
import AssistancesPerUsertypeVue from "@/components/reports/AssistancesPerUsertype.vue";

import ScheduleStats from "@/components/reports/ScheduleStats.vue";
import UsersAge from "@/components/reports/UsersAge.vue";
import UsersInRisk from "@/components/reports/UsersInRisk.vue";
import FinancialSummary from "@/components/reports/FinancialSummary.vue";

import UserPaselibreTransitions from "@/components/reports/UserPaselibreTransitions.vue";
import UsersRankingVue from "@/components/reports/UsersRanking.vue";

import ActiveUsersHistory from "@/components/reports/ActiveUsersHistory.vue";

import AdminDashboard from "@/components/dashboard/AdminDashboard.vue";



export default {
    components: {
        Alerts,
        Payments,
        NewSubscriptions,
        SatisfactionSurvey,
        AssistancesPerUsertypeVue,
        ScheduleStats,
        UsersAge,
        UsersInRisk,
        FinancialSummary,
        UserPaselibreTransitions,
        UsersRankingVue,
        ActiveUsersHistory,
        AdminDashboard,
    },
    data() {
        return {
            reports: [],
            selectedReport: null,
            groupIconHash: {
                Socios: "mdi-account-multiple",
                Asistencias: "mdi-account-check",
                Alertas: "mdi-alert",
                Pagos: "mdi-cash",
            }
        };
    },
    computed: {
        groupedReports() {
            return this.reports.reduce((groups, report) => {
                const groupName = report.group || "Other";
                if (!groups[groupName]) groups[groupName] = [];
                groups[groupName].push(report);
                return groups;
            }, {});
        }
    },
    methods: {
        async fetchReports() {
            const db = getFirestore();
            const querySnapshot = await getDocs(collection(db, "reports"));

            this.reports = querySnapshot.docs.map(doc => {
                return { id: doc.id, ...doc.data() };
            });
        },
        selectReport(report) {
            this.selectedReport = report;

        },
        generateFakeReports() {
            const reports = [];

            reports.push({
                id: 188,
                title: `Activos por dia`,
                group: "Socios"
            })

            reports.push({
                id: 0,
                title: `Nuevos, Bajas y Renovaciones`,
                group: "Socios"
            })



            reports.push({
                id: 1,
                title: `Información de usuarios`,
                group: "Socios"
            })


            reports.push({
                id: 2,
                title: `Encuesta de satisfacción`,
                group: "Socios"
            })

            reports.push({
                id: 4,
                title: `Usuarios en riesgo`,
                group: "Socios"
            })

            reports.push({
                id: 7,
                title: `Transiciones Socio->PaseLibre`,
                group: "Socios"
            })

            reports.push({
                id: 8,
                title: `Ranking de usuarios`,
                group: "Socios"
            })




            reports.push({
                id: 432,
                title: `Asistencias por tipo`,
                group: "Asistencias"
            })

            reports.push({
                id: 3,
                title: `Asistencias por horario`,
                group: "Asistencias"
            })

            /* 

            reports.push({
                id: 2,
                title: `Totales`,
                content: `Nuevas suscripciones: 10`,
                group: "Asistencias"
            })

            reports.push({
                id: 5,
                title: `Asistencias por tipo de cuenta`,
                content: `Nuevas suscripciones: 10`,
                group: "Asistencias"
            })

            reports.push({
                id: 6,
                title: `Asistencias por horario`,
                content: `Nuevas suscripciones: 10`,
                group: "Asistencias"
            })

             reports.push({
                id: 8,
                title: `Asistencias por socio`,
                content: `Nuevas suscripciones: 10`,
                group: "Asistencias"
             })

 */



            reports.push({
                id: 9,
                title: `Alertas por dia y gestion`,
                content: `Nuevas suscripciones: 10`,
                group: "Alertas"
            })

            reports.push({
                id: 10,
                title: `Confirmaciones de pago`,
                content: `Nuevas suscripciones: 10`,
                group: "Pagos"
            })

            reports.push({
                id: 11,
                title: `Resumen financiero`,
                content: `Nuevas suscripciones: 10`,
                group: "Pagos"
            })

            reports.push({
                id: 999,
                title: `Dashboard`,
                group: "Admin"
            });






            return reports;
        }

    },
    mounted() {
        //this.fetchReports();
        this.reports = this.generateFakeReports();
    }
};
</script>

<style>
.v-date-picker-table .v-btn.v-btn--active {
    color: #000 !important;
}

.v-data-footer {
    flex-wrap: unset !important;
    margin-right: 4px !important
}

.v-application--is-ltr .v-data-footer__pagination {
    margin: 0 16px 0 12px !important;
}

.v-date-picker-table__current {
    color: unset !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background-color: transparent !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
    background: #000 !important;
    color: #fff !important;
    border-color: var(--v-primary-base) !important;
}
</style>
