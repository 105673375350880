<template>
  <v-container>
    <v-row>
      <!-- Main Workout Column -->
      <v-col cols="12" :sm="!$props.planificationsArray ? 6 : 12">


        <v-card outlined>
          <v-card-title>
            Principal
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="resetMainWorkout" v-if="mainWorkout && mainWorkout.plan_id">
              Limpiar planificación
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!--  <div>
              <strong>Tipo:</strong> {{ mainWorkout.type || 'Not set' }}
            </div> -->

            <template v-if="getPlanificationName(mainWorkout.plan_id)">
              <div>
                <strong>Planificación:</strong>

                <v-avatar class="ml-4" :color="getRoutineColor(mainWorkout.plan_id)" size="20"></v-avatar>

                {{ getPlanificationName(mainWorkout.plan_id) || 'Sin planificación' }}
              </div>
              <div>
                <strong class="mr-4">Fecha inicio:</strong> {{ mainWorkout.start || 'Sin fecha inicio' }}
              </div>

              <div v-if="mainWorkout.end">
                <strong>Fecha fin:</strong> {{ mainWorkout.end }}
              </div>
            </template>


            <template v-if="!getPlanificationName(mainWorkout.plan_id) && !loading">
              <v-alert type="info" text>
                No hay planificación Principal
              </v-alert>
            </template>

            <v-skeleton-loader type="list-item-two-line" v-if="loading"></v-skeleton-loader>

            <!--   <div>
              <strong>End Date:</strong> {{ mainWorkout.end || 'Ongoing' }}
            </div> -->
          </v-card-text>
          <v-divider />

          <v-card-actions>


            <v-spacer></v-spacer>

            <v-btn @click="startEditMainWorkout">
              <v-icon left>
                mdi-pencil
              </v-icon>
              Editar</v-btn>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>

        <!-- Edit Main Workout Dialog -->
        <v-dialog v-model="editMainWorkoutDialog" max-width="600px" persistent>
          <v-card>
            <v-card-title>
              Editar Principal
              <v-spacer />
              <v-btn icon @click="cancelEditMainWorkout">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider />

            <v-card-text v-if="editMainWorkoutDialog" class="pt-4">
              <v-form ref="mainWorkoutForm">
                <!--   <v-text-field
                  v-model="editedMainWorkout.type"
                  label="Tipo"
                ></v-text-field> -->

                <v-autocomplete filled v-model="editedMainWorkout.plan_id" :items="availablePlanificationsCalendars"
                  item-text="name" item-value="id" label="Planificación"></v-autocomplete>

                <v-menu v-model="menuStart" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="editedMainWorkout.start" label="Fecha inicio" readonly filled v-bind="attrs"
                      v-on="on" append-icon="mdi-calendar"></v-text-field>
                  </template>
                  <v-date-picker v-model="editedMainWorkout.start" no-title scrollable
                    :allowed-dates="allowedDates"></v-date-picker>
                </v-menu>

                <v-menu v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="editedMainWorkout.end" label="Fecha fin (opcional)" readonly filled
                      v-bind="attrs" v-on="on" append-icon="mdi-calendar"></v-text-field>
                  </template>
                  <v-date-picker v-model="editedMainWorkout.end" no-title scrollable></v-date-picker>
                </v-menu>
              </v-form>
            </v-card-text>
            <v-card-actions>

              <v-spacer></v-spacer>
              <v-btn text class="mr-4" @click="cancelEditMainWorkout">Cancelar</v-btn>


              <v-btn color="success" @click="saveMainWorkout" :loading="loading">
                <v-icon left>mdi-check</v-icon>
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>

      <!-- Routines Column -->
      <v-col cols="12" sm="6" v-show="!$props.planificationsArray">

        <v-card outlined>
          <v-card-title>
            Secundarias

          </v-card-title> <v-divider></v-divider>

          <v-card-text>

            <v-skeleton-loader type="list-item-two-line" v-if="loading"></v-skeleton-loader>

            <v-alert type="info" text v-if="routines.length === 0 && !loading">
              No hay rutinas añadidas
            </v-alert>


            <v-list v-if="routines.length > 0 && !loading">

              <template v-for="(routine, index) in routines">
                <v-list-item :key="index">
                  <v-list-item-avatar :color="getRoutineColor(routine)">
                    <v-icon>mdi-dumbbell</v-icon>

                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ getRoutineName(routine) }}</v-list-item-title>

                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="removeRoutine(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="index + 'routine'"></v-divider>
              </template>

            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="openAddRoutineDialog">
              <v-icon left>
                mdi-pencil
              </v-icon>
              Editar
            </v-btn>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>


      </v-col>
    </v-row>

    <!-- Add Routine Dialog -->
    <v-dialog v-model="addRoutineDialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>
          Añadir Rutinas
          <v-spacer />
          <v-btn icon @click="addRoutineDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4">
          <v-autocomplete filled v-model="selectedRoutines" :items="availablePlanifications" item-text="name"
            item-value="id" label="Rutinas" multiple></v-autocomplete>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text @click="addRoutineDialog = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="addSelectedRoutines" :loading="loading">
            <v-icon left>mdi-plus</v-icon>
            Añadir
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { getFirestore, collection, getDocs, doc, setDoc, addDoc } from 'firebase/firestore';
import { logAuditEvent } from '@/error/audit.js';
///        await logAuditEvent('update',this.$store.state.Auth.token.claims.user_id,`User ${this.user.id} re-enabled`)

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    planificationsArray: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      routines: [],
      mainWorkout: {
        type: null,
        plan_id: null,
        start: null,
        end: null,
      },
      planifications: [], // To store planifications fetched from Firestore
      menuStart: false,
      menuEnd: false,
      addRoutineDialog: false,
      selectedRoutines: [], // For the v-autocomplete in the dialog
      editMainWorkoutDialog: false,
      editedMainWorkout: null,
      previousMainWorkoutPlanId: null,


    };
  },
  computed: {
    availablePlanifications() {
      // Planifications not in user's routines and not the main routine
      // only return planifications that are not of type calendar or schedule
      return this.planifications.filter((plan) => !this.routines.includes(plan.id) && plan.type !== 'matrix' && plan.type !== 'matrixtext');
    },
    availablePlanificationsCalendars() {
      // Planifications not in user's routines and not the main routine
      // only return planifications that are of type calendar or schedule
      return this.planifications.filter((plan) => !this.routines.includes(plan.id) && (plan.type === 'matrix' || plan.type === 'matrixtext'));
    },
  },
  methods: {
    allowedDates(date) {
      // only all mondays of the year must be allowed , date is a YYYY-MM-DD string
      const d = new Date(date);
      return d.getDay() === 0;
    },

    async fetchPlanifications() {
      if (this.$props.planificationsArray) {
        this.planifications = this.$props.planificationsArray.filter((plan) => plan.type === 'matrix' || plan.type === 'matrixtext');
        return;
      }

      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, 'planifications'));
      this.planifications = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
    },
    getPlanificationName(planificationId) {
      const plan = this.planifications.find((p) => p.id === planificationId);
      return plan ? plan.name : false;
    },
    startEditMainWorkout() {
      this.previousMainWorkoutPlanId = this.mainWorkout.plan_id;
      this.editedMainWorkout = { ...this.mainWorkout };
      this.editMainWorkoutDialog = true;
    },
    async saveMainWorkout() {

      this.loading = true;
      // Compare the plan_id to see if it has changed
      if (this.previousMainWorkoutPlanId && this.previousMainWorkoutPlanId !== this.editedMainWorkout.plan_id) {
        // The plan_id has changed
        // Set the end date of the previous main workout to now and store it in workoutsHistory
        const oldWorkout = {
          ...this.mainWorkout,
          end: this.editedMainWorkout && this.editedMainWorkout.end ? this.editedMainWorkout.end : new Date().toISOString().split('T')[0], // Set end date to today
        };
        // Save oldWorkout to workoutsHistory
        await this.saveWorkoutHistory(oldWorkout);
        logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User ${this.user.id} updated main workout obj ${JSON.stringify(oldWorkout)}`)
      }

      // Update mainWorkout with the edited data
      this.mainWorkout = { ...this.editedMainWorkout };

      // Save the mainWorkout to Firestore or update the user object
      await this.saveMainWorkoutToFirestore();

      this.loading = false;
      this.editMainWorkoutDialog = false;


      this.$emit('main-workout-updated', this.mainWorkout);


    },
    cancelEditMainWorkout() {
      this.editMainWorkoutDialog = false;
      this.editedMainWorkout = null;
    },
    async saveWorkoutHistory(workout) {
      // Save the workout to the user's workoutsHistory subcollection
      const db = getFirestore();
      const userId = this.user.id; // Ensure the user object contains the id
      const workoutsHistoryRef = collection(db, 'users', userId, 'workoutsHistory');
      await addDoc(workoutsHistoryRef, workout);
    },
    async saveMainWorkoutToFirestore() {

      try {
        // Save the mainWorkout to the user's document in Firestore
        const db = getFirestore();
        const userId = this.user.id; // Ensure the user object contains the id
        const userDocRef = doc(db, 'users', userId);
        await setDoc(userDocRef, { mainWorkout: this.mainWorkout }, { merge: true });
        logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User ${this.user.id} updated main workout obj ${JSON.stringify(this.mainWorkout)}`)
        this.$notify({
          type: 'success',
          title: 'Exito',
          text: 'Planificación principal actualizada',
        })
      }
      catch (e) {

        logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `User ${this.user.id} error updating main workout obj ${JSON.stringify(this.mainWorkout)}`)
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Error al actualizar planificación principal',
        })
      }

    },
    openAddRoutineDialog() {
      this.selectedRoutines = [];
      this.addRoutineDialog = true;
    },
    async addSelectedRoutines() {

      this.loading = true;
      // Add selected routines to user's routines
      this.routines = [...this.routines, ...this.selectedRoutines];

      // Save the updated routines to Firestore
      await this.saveRoutinesToFirestore();
      this.addRoutineDialog = false;
      this.loading = false;

    },
    removeRoutine(index) {
      this.routines.splice(index, 1);
      // Save the updated routines to Firestore
      this.saveRoutinesToFirestore();
    },
    async saveRoutinesToFirestore() {

      try {
        // Save the routines to the user's document in Firestore
        const db = getFirestore();
        const userId = this.user.id; // Ensure the user object contains the id
        const userDocRef = doc(db, 'users', userId);
        await setDoc(userDocRef, { routines: this.routines }, { merge: true });
        logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `User ${this.user.id} updated routines obj ${JSON.stringify(this.routines)}`)
        this.$notify({
          type: 'success',
          title: 'Exito',
          text: 'Rutinas actualizadas',
        })
      }
      catch (e) {
        logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, `User ${this.user.id} error updating routines obj ${JSON.stringify(this.routines)}`)
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Error al actualizar rutinas',
        })
      }


    },
    getRoutineName(planificationId) {
      const plan = this.planifications.find((p) => p.id === planificationId);
      return plan ? plan.name : 'Unknown';
    },
    getRoutineColor(planificationId) {
      const plan = this.planifications.find((p) => p.id === planificationId);
      return plan ? plan.color : '#FFFFFF'; // Default to white if no color found
    },
    populateUserData() {
      // Populate data from provided user object
      this.routines = this.user.routines || [];
      this.mainWorkout = this.user.mainWorkout || {
        type: null,
        plan_id: null,
        start: null,
        end: null,
      };

      this.loading = false;
    },
    async resetMainWorkout() {

      let confirm = await this.$confirm(
        "¿Estás seguro de que deseas eliminar la planificación principal? Al hacerlo no se guardará el historial de la planificación actual.",
        {
          color: "error", title: "Resetear planificación principal",
          buttonTrueText: "CONFIRMAR",
          buttonFalseText: "CANCELAR"
        }
      );
      if (!confirm) return;




      this.mainWorkout = {
        type: null,
        plan_id: null,
        start: null,
        end: null,
      };

      // Save the mainWorkout to Firestore, then emit the reset event
      const db = getFirestore();
      const userId = this.user.id; // Ensure the user object contains the id
      const userDocRef = doc(db, 'users', userId);
      setDoc(userDocRef, { mainWorkout: this.mainWorkout }, { merge: true });

      this.$emit('main-workout-reset', this.mainWorkout);
      this.$emit('main-workout-updated', this.mainWorkout);

    },
  },
  async mounted() {
    await this.fetchPlanifications();
    this.populateUserData();
  },
};
</script>
