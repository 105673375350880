<template>
    <v-card class="pa-4">
        <v-card-title class="text-h5 mb-4">
            Fix Payment Totals
        </v-card-title>

        <v-card-text>
            <div v-if="!isProcessing && !isComplete" class="mb-4">
                <p class="mb-2">This will convert all payment totals from strings to integers.</p>
                <p class="mb-2">Found {{ totalPayments }} payments to process.</p>
                <v-btn color="primary" :disabled="totalPayments === 0" @click="fixPayments">
                    Start Fix
                </v-btn>
            </div>

            <div v-if="isProcessing">
                <p class="mb-2">Processing payments...</p>
                <p class="mb-2">Progress: {{ processedCount }} / {{ totalPayments }}</p>
                <v-progress-linear :value="(processedCount / totalPayments) * 100" color="primary" height="25">
                    <template v-slot:default>
                        {{ Math.round((processedCount / totalPayments) * 100) }}%
                    </template>
                </v-progress-linear>
            </div>

            <div v-if="isComplete" class="success--text">
                <p class="mb-2">✅ All payments have been processed!</p>
                <p>Fixed payments: {{ fixedCount }}</p>
                <p>Already correct: {{ totalPayments - fixedCount }}</p>
            </div>

            <v-alert v-if="error" type="error" class="mt-4">
                {{ error }}
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>
import { collection, query, getDocs, getFirestore, writeBatch } from 'firebase/firestore'

export default {
    name: 'FixPaymentTotals',

    data() {
        return {
            isProcessing: false,
            isComplete: false,
            error: null,
            totalPayments: 0,
            processedCount: 0,
            fixedCount: 0
        }
    },

    async mounted() {
        await this.countPayments()
    },

    methods: {
        async countPayments() {
            try {
                const db = getFirestore()
                const paymentsQuery = query(collection(db, 'payments'))
                const snapshot = await getDocs(paymentsQuery)
                this.totalPayments = snapshot.size
            } catch (error) {
                console.error('Error counting payments:', error)
                this.error = 'Error counting payments: ' + error.message
            }
        },

        async fixPayments() {
            this.isProcessing = true
            this.error = null
            this.processedCount = 0
            this.fixedCount = 0

            try {
                const db = getFirestore()
                const paymentsQuery = query(collection(db, 'payments'))
                const snapshot = await getDocs(paymentsQuery)

                // Process in batches of 500 (Firestore batch limit)
                const batchSize = 500
                let batch = writeBatch(db)
                let batchCount = 0

                for (const doc of snapshot.docs) {
                    const data = doc.data()
                    const currentTotal = data.total

                    // Only update if it's a string and can be parsed to a number
                    if (typeof currentTotal === 'string') {
                        const newTotal = parseFloat(currentTotal)
                        if (!isNaN(newTotal)) {
                            batch.update(doc.ref, { total: newTotal })
                            this.fixedCount++
                            batchCount++
                        }
                    }

                    // Commit batch if it reaches the size limit
                    if (batchCount === batchSize) {
                        await batch.commit()
                        batch = writeBatch(db)
                        batchCount = 0
                    }

                    this.processedCount++
                }

                // Commit any remaining updates
                if (batchCount > 0) {
                    await batch.commit()
                }

                this.isComplete = true
            } catch (error) {
                console.error('Error fixing payments:', error)
                this.error = 'Error fixing payments: ' + error.message
            } finally {
                this.isProcessing = false
            }
        }
    }
}
</script>