<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12">

                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330"
                    min-width="330">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field filled hide-details="" v-model="dateRange" label="Seleccionar un rango de fechas"
                            readonly v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
                    </template>
                    <v-date-picker v-model="dateRange" range width="100%" scrollable
                        :min="dateRange.length > 0 ? dateRange[0] : null">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary darken-1" @click=" dateRange = []">Cancelar</v-btn>
                        <v-btn text color="primary darken-1" @click="menu = false; fetchAllData()">Confirmar</v-btn>

                    </v-date-picker>
                </v-menu>

                <v-alert text v-if="!loading && series[0].data.length">
                    <div class="text-subtitle-1 font-weight-bold mb-2">Estadísticas del período</div>
                    <v-row>
                        <v-col cols="12" sm="4">
                            <div class="caption grey--text">Usuarios máximos</div>
                            <div class="subtitle-1">{{ maxUsers.toLocaleString() }}</div>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <div class="caption grey--text">Usuarios mínimos</div>
                            <div class="subtitle-1">{{ minUsers.toLocaleString() }}</div>
                        </v-col>
                        <v-col cols="12" sm="4">
                            <div class="caption grey--text">Crecimiento</div>
                            <div class="subtitle-1"
                                :class="{ 'success--text': percentageGrowth > 0, 'error--text': percentageGrowth < 0 }">
                                {{ percentageGrowth }}%
                            </div>
                        </v-col>
                    </v-row>
                </v-alert>

                <apexchart type="line" :options="chartOptions" :series="series" v-if="!loading"></apexchart>
                <v-skeleton-loader type="image" v-else></v-skeleton-loader>



            </v-col>

            <v-col cols="12">
                <v-alert type="info" text>
                    Este gráfico muestra la cantidad de usuarios 'activos' en el sistema en un rango de fechas, activos
                    no significa que asistan a clases o usen la aplicación, sino que están habilitados para hacerlo.
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";
import ApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
    components: {
        apexchart: ApexCharts,
    },
    data() {
        return {
            menu: false,

            loading: false,
            dateRange: [],
            users: [],
            disabledHistory: [],
            chartOptions: {

                theme: {
                    mode: this.$store.state.isDark ? 'dark' : 'light',
                    palette: 'palette1'
                },
                chart: {
                    id: "users-availability-report",
                    toolbar: {
                        show: false
                    },
                },
                xaxis: {
                    categories: [],
                },
                title: {
                    text: "Cantidad de usuarios activos",
                    align: "left",
                },
            },
            series: [
                {
                    name: "Usuarios activos",
                    data: [],
                },
            ],
            maxUsers: 0,
            minUsers: 0,
            monthlyStats: {},
            percentageGrowth: 0,


        };
    },
    created() {
        this.fetchAllData();
    },
    methods: {
        async fetchAllData() {


            let start, end;

            if (this.dateRange.length == 2) {
                start = this.dateRange[0]
                end = this.dateRange[1]
            } else {
                // here start is the start of the month and end is today at 23:59:59
                let date = moment()
                let startOfMonth = moment().startOf('month')

                start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00")
                end = new Date(date.format('YYYY-MM-DD') + " 23:59:59")

                this.dateRange = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')]
            }

            this.loading = true;
            const functions = getFunctions();
            const getUserAndDisabledHistory = httpsCallable(functions, "getUserAvailabilityData");

            try {
                const result = await getUserAndDisabledHistory({});
                this.users = result.data.users;
                this.disabledHistory = result.data.disabledHistory;
                this.updateDisplayedData(); // Initialize display
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        updateDisplayedData() {
            const startDate = moment(this.dateRange[0]).startOf("day");
            const endDate = moment(this.dateRange[1]).endOf("day");

            const filteredCategories = [];
            const filteredData = [];

            // Reset statistics
            this.maxUsers = 0;
            this.minUsers = Infinity;

            // Create a map of user states
            let userStates = {};

            // Initialize all users based on their creation date and initial state
            this.users.forEach(user => {
                const createdDate = moment(user.createddate);
                if (createdDate.isValid()) {
                    userStates[user.userid] = {
                        createdDate: createdDate,
                        isActive: !user.disabled,
                        events: []
                    };
                }
            });

            // Add all status change events to user histories
            this.disabledHistory.forEach(record => {
                const eventDate = moment(record.date);
                if (eventDate.isValid() && userStates[record.id]) {
                    userStates[record.id].events.push({
                        date: eventDate,
                        isActive: record.type === 'enabled'
                    });
                }
            });

            // Sort events for each user
            Object.values(userStates).forEach(user => {
                user.events.sort((a, b) => a.date.valueOf() - b.date.valueOf());
            });

            // For each day in the range, calculate active users
            for (let date = startDate.clone(); date <= endDate; date.add(1, 'days')) {
                let activeUsersCount = 0;

                // Check each user's state for this date
                Object.entries(userStates).forEach(([userId, userData]) => {
                    // Skip if user wasn't created yet
                    if (userData.createdDate > date) {
                        return;
                    }

                    // Start with initial state
                    let currentState = userData.isActive;

                    // Apply all events up to this date
                    for (const event of userData.events) {
                        if (event.date <= date) {
                            currentState = event.isActive;
                        } else {
                            break;
                        }
                    }

                    if (currentState) {
                        activeUsersCount++;
                    }
                });

                const dateStr = date.format('YYYY-MM-DD');
                filteredCategories.push(dateStr);
                filteredData.push(activeUsersCount);

                // Update statistics
                this.maxUsers = Math.max(this.maxUsers, activeUsersCount);
                if (activeUsersCount > 0) {
                    this.minUsers = Math.min(this.minUsers, activeUsersCount);
                }
            }

            // Calculate growth
            let percentageGrowth = 0;
            if (filteredData.length > 1) {
                const firstValue = filteredData[0];
                const lastValue = filteredData[filteredData.length - 1];
                if (firstValue > 0) {
                    percentageGrowth = ((lastValue - firstValue) / firstValue) * 100;
                }
            }

            // Update the chart
            this.chartOptions.xaxis.categories = filteredCategories;
            this.series[0].data = filteredData;
            this.percentageGrowth = percentageGrowth.toFixed(1);
            this.loading = false;
        },
    },
};
</script>

<style scoped>
/* Add any additional styling here */
</style>
