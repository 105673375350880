<template>
  <v-app id="app" v-bind:class="{ 'isLogged': isLogged }">

    <monthly-rate-button v-if="isLogged"></monthly-rate-button>

    <Navbar @toggleDrawer="toggleDrawer" @toggleDrawerRight="toggleDrawerRight" :alerts="alerts"
      :drawerAlerts="drawerAlerts" @toggleDrawerRightAlerts="toggleDrawerRightAlerts" :drawer="drawer"
      :drawer2="drawer2" :noticationUpdatedAt="noticationUpdatedAt" :news="content" style="z-index:10;" v-if="isLogged">
    </Navbar>

    <v-navigation-drawer style="z-index: 5;" :fixed="!$vuetify.breakpoint.smAndDown"
      :permanent="!$vuetify.breakpoint.smAndDown" :expand-on-hover="!$vuetify.breakpoint.smAndDown"
      :app="$vuetify.breakpoint.smAndDown" :temporary="$vuetify.breakpoint.smAndDown" :width="250" v-model="drawer"
      v-if="isLogged">

      <v-list v-bind:style="{ 'margin-top': isCapacitor ? '100px' : '50px' }" nav
        :dense="!$vuetify.breakpoint.smAndDown">
        <v-list-item-group color="primary">

          <v-list-item :key="'admin'" :to="'/admin'" @click="drawer = false"
            v-if="this.$store.state.Auth && this.$store.state.Auth.token && this.$store.state.Auth.token.claims.type && (this.$store.state.Auth.token.claims.type == 'superuser' || this.$store.state.Auth.token.claims.type == 'admin')">
            <v-list-item-icon>
              <v-icon>
                mdi-account-cog
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Administrador</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mb-1"></v-divider>

          <v-list-item v-for="(item, i) in items" :key="i" :to="item.route" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item :key="'wp-btn'" target="_blank" href="https://wa.me/+59898996900?text=Hola!%20"
            @click="drawer = false"
            v-if="this.$store.state.Auth && this.$store.state.Auth.token && this.$store.state.Auth.token.claims.type && (this.$store.state.Auth.token.claims.type == 'superuser' || this.$store.state.Auth.token.claims.type == 'admin')">
            <v-list-item-icon>
              <v-icon color="green">
                mdi-whatsapp
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Contacto</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item @click="logout" class="mt-1">
            <v-list-item-icon>
              <v-icon color="error">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        </v-list-item-group>
      </v-list>


    </v-navigation-drawer>

    <v-navigation-drawer app right temporary v-model="drawer2" width="300" v-if="isLogged">
      <v-flex xs12 v-bind:style="{ 'margin-top': isCapacitor ? '100px' : '60px' }">
        <v-subheader class="text-h6 my-4">
          <v-icon left class="mr-2">
            mdi-bullhorn
          </v-icon>
          Anuncios

          <v-divider class="ml-2"></v-divider>

          <EditNews ref="editNews" @updateNews="updateNews" :content="content"
            v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
          </EditNews>

        </v-subheader>

        <v-card class="ma-2 elevation-0" color="transparent">
          <v-card-text v-if="(!content || content == '') && loadingNews">
            <v-progress-linear v-if="loadingNews" indeterminate color="primary"></v-progress-linear>
            Cargando...
          </v-card-text>
          <template v-else>
            <v-card-text class="text-center" v-if="(!content || content == '') && !loadingNews"> No hay
              noticias</v-card-text>
            <v-card v-else class="elevation-0">
              <v-card-text v-html="content" class="vue-editor"></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <span class="caption">
                  {{ noticationUpdatedAt ? new Date(noticationUpdatedAt).toLocaleString() : '' }}
                </span>
              </v-card-actions>


            </v-card>
          </template>
        </v-card>
      </v-flex>
    </v-navigation-drawer>

    <v-navigation-drawer app right temporary v-model="drawerAlerts" width="300" v-if="isLogged">

      <v-flex xs12 v-bind:style="{ 'margin-top': isCapacitor ? '100px' : '60px' }">
        <v-subheader class="text-h6 my-4">


          <v-icon left>
            mdi-bell
          </v-icon>
          Notificaciones

          <v-divider class="ml-2"></v-divider>





        </v-subheader>

      </v-flex>

      <template v-if="alerts.length > 0">


        <v-alert type="info" :color="getColorForAlertType(item.type)" v-for="(item, i) in alerts" :key="i" class="mx-2"
          border="left">
          {{ getLabelForAlertType(item.type) }} <br>

          <p class="caption text-right ma-0">

            {{ new Date(item.date.seconds * 1000).toLocaleString()
            }}</p>




        </v-alert>







      </template>
      <v-alert v-else class="ma-4" color="primary darken-3" border="left">
        No hay alertas
      </v-alert>

    </v-navigation-drawer>

    <v-main id="main-container">
      <transition name="fade-transition" mode="out-in">
        <router-view key="router"></router-view>
      </transition>
    </v-main>


    <RingBottomNavigation
      v-if="isLogged && $vuetify.breakpoint.smAndDown && $store.state.Auth.token.claims.type == 'admin'" id="bottomnav"
      v-bind:class="{ 'isIos': isIos && isStandalone }" :options="optionsAdmin" v-model="selected" />

    <RingBottomNavigation
      v-if="isLogged && $vuetify.breakpoint.smAndDown && $store.state.Auth.token.claims.type == 'superuser'"
      id="bottomnav" v-bind:class="{ 'isIos': isIos && isStandalone }" :options="optionsAdmin" v-model="selected" />

    <RingBottomNavigation
      v-if="isLogged && $vuetify.breakpoint.smAndDown && $store.state.Auth.token.claims.type == 'entrenador'"
      id="bottomnav" v-bind:class="{ 'isIos': isIos && isStandalone }" :options="optionsTrainer" v-model="selected" />

    <RingBottomNavigation
      v-if="isLogged && $vuetify.breakpoint.smAndDown && $store.state.Auth.token.claims.type != 'superuser' && $store.state.Auth.token.claims.type != 'admin' && $store.state.Auth.token.claims.type != 'entrenador'"
      id="bottomnav" v-bind:class="{ 'isIos': isIos && isStandalone }" :options="optionsUsers" v-model="selected" />


    <v-bottom-navigation style="height: 64px;" v-bind:class="{ 'isIos': isIos && isStandalone }" app color="primary"
      v-if="isLogged && $vuetify.breakpoint.smAndDown /*  && isStandalone */ && $route.path != '/qr'" grow>

      <v-btn x-large style="height: 100%;" :to="'/inicio'">
        <span>Inicio</span>

        <v-icon>
          mdi-view-dashboard
        </v-icon>
      </v-btn>

      <v-btn x-large :to="'/reservas'" style="height: 100%;"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'entrenador')">
        <span>Reservas</span>

        <v-icon>mdi-list-status
        </v-icon>
      </v-btn>

      <v-btn x-large :to="'/admin'" style="height: 100%;"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <span>Admin</span>

        <v-icon>mdi-account-cog
        </v-icon>
      </v-btn>

      <v-btn x-large :to="'/rutinas'" style="height: 100%;"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'entrenador')">
        <span>Planes</span>

        <v-icon>mdi-playlist-edit
        </v-icon>
      </v-btn>

      <v-btn x-large :to="'/clases'" style="height: 100%;"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type != 'superuser' && $store.state.Auth.token.claims.type != 'admin' && $store.state.Auth.token.claims.type != 'entrenador' && $store.state.Auth.token.claims.type != 'personalizado')">
        <span>Clases</span>

        <v-icon>mdi-calendar-clock
        </v-icon>
      </v-btn>
      <v-btn x-large :to="'/plan'" style="height: 100%;"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'personalizado' || $store.state.Auth.token.claims.type == 'online')">
        <span>Mi plan</span>

        <v-icon large>mdi-play-outline
        </v-icon>
      </v-btn>


      <v-btn x-large :to="'/perfil'" style="height: 100%;">
        <span>Perfil</span>

        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-overlay v-model="overlay" persistent>
      <v-card color="primary darken-2" class="ma-4 rounded-lg" max-width="400">
        <v-card-title class="d-flex align-center pa-4">
          <v-icon size="32" class="mr-3">mdi-alert-circle</v-icon>
          <span class="text-h5">Nueva actualización</span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <p class="text-body-1">
            Se ha detectado una nueva versión de la aplicación. Para continuar usando todas las funcionalidades, es
            necesario actualizar.
          </p>
        </v-card-text>

        <v-card-actions class="pa-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn v-if="wb" color="success" elevation="2" class="rounded-lg" large :loading="loadingBTN"
            @click="confirmUpdate">
            <v-icon left>mdi-refresh</v-icon>
            Actualizar ahora
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-overlay>

  </v-app>
</template>

<script>
import { mapState } from "vuex";
//import firebase from "./firebase";
import Navbar from "@/components/Navbar.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginManager from './models/login.js';
import { Workbox } from 'workbox-window';
import { Capacitor } from '@capacitor/core';
import {
  getFirestore, doc, onSnapshot, collection, query, where, orderBy, setDoc

} from 'firebase/firestore';
import EditNews from '@/components/EditNews.vue';
import MonthlyRateButton from '@/components/customerSuccess/MonthlyRateButton.vue';

export default {
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    if (this.unsubscribeAlerts) {
      this.unsubscribeAlerts();
    }
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('focus', this.updateLastInteractionTime);
    window.removeEventListener('blur', this.calculateElapsedTime);
    //window.removeEventListener('focus', this.checkForWorkboxUpdates);

    const editorContent = document.querySelector('.vue-editor'); // Same selector as before
    if (editorContent) {
      const links = editorContent.querySelectorAll('a');
      links.forEach(link => {
        link.removeEventListener('click', this.handleLinkClick);
      });
    }
  },
  data() {
    return {
      selected: 1,
      optionsUsers: [
        { id: 1, icon: 'mdi mdi-home ', title: 'Inicio', path: '/inicio' },
        { id: 2, icon: 'mdi mdi-calendar-clock', title: 'Clases', path: '/clases' },
        { id: 3, icon: 'mdi mdi-finance', title: 'Progreso', path: '/progreso' },
        { id: 5, icon: 'mdi mdi-account-circle', title: 'Cuenta', path: '/perfil' },
      ],
      optionsAdmin: [
        { id: 1, icon: 'mdi mdi-home ', title: 'Inicio', path: '/inicio' },
        { id: 2, icon: 'mdi mdi-list-status', title: 'Reservas', path: '/reservas' },
        { id: 3, icon: 'mdi mdi-qrcode-scan', title: 'Check-In', path: '/qr' },
        { id: 4, icon: 'mdi mdi-cog', title: 'Admin', path: '/admin' },
        { id: 5, icon: 'mdi mdi-account-circle', title: 'Cuenta', path: '/perfil' },
      ],
      optionsTrainer: [
        { id: 1, icon: 'mdi mdi-home ', title: 'Inicio', path: '/inicio' },
        { id: 2, icon: 'mdi mdi-list-status', title: 'Reservas', path: '/reservas' },
        { id: 3, icon: 'mdi mdi-qrcode-scan', title: 'Check-In', path: '/qr' },
        { id: 4, icon: 'mdi mdi-playlist-edit', title: 'Planes', path: '/rutinas' },
        { id: 5, icon: 'mdi mdi-account-circle', title: 'Cuenta', path: '/perfil' },
      ],
      isCapacitor: Capacitor.isNativePlatform(),
      smAndDown: false,
      value: 0,
      drawer: false,
      drawer2: false,
      drawerAlerts: false,
      showPadding: true,
      items: [],
      overlay: false,
      isIos: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
      isAndroid: /android/i.test(navigator.userAgent),
      isChrome: navigator.userAgent.indexOf('Chrome') != -1,
      isStandalone: (window.navigator.standalone !== undefined) ? window.navigator.standalone : false,
      content: '',
      loadingNews: false,
      noticationUpdatedAt: null,
      unsuscribe: null,
      lastInteractionTime: new Date(),
      wb: new Workbox('/service-worker.js'),
      loadingBTN: false,
      unsubscribe: null,
      unsubscribeAlerts: null,
      alerts: [],

    }

  },
  components: {
    Navbar, EditNews, MonthlyRateButton
  },
  computed: {
    ...mapState({
      isLogged: (state) => state.Auth.isLogged,
      token: (state) => state.Auth.token,
      initials: (state) => {
        let arr = state.Auth.token.claims.displayName.split(" ");
        if (arr.length === 1) return arr[0];
        else return (arr[0][0] + arr[1][0]).toUpperCase();
      },
    }),

  },
  async created() {
    this.$i18n.locale = this.$store.state.lang;
    this.$vuetify.theme.dark = this.$store.state.isDark;
    this.$vuetify.theme.themes.dark.primary = this.$store.state.primary;
    this.$vuetify.theme.themes.light.primary = this.$store.state.primary;
    await this.subscribeGoogleAuth();

    this.smAndDown = this.$vuetify.breakpoint.smAndDown;

    this.items = [
      { title: 'Inicio', icon: 'mdi-view-dashboard', route: '/' },
      { title: 'Reservas', icon: 'mdi-calendar-clock', route: '/clases' },
      { title: 'Progreso', icon: 'mdi-finance', route: '/progreso' },
      //{ title: 'Mi Plan', icon: 'mdi-play-outline', route: '/plan' },
      { title: 'Cronómetro', icon: 'mdi-timer-outline', route: '/cronometro' },
      { title: 'Perfil', icon: 'mdi-account', route: '/perfil' }
    ]

    if (this.smAndDown) {
      this.items = this.items.splice(1, this.items.length - 1)
    }

    // Then, inside your method or lifecycle hook:
    this.wb.addEventListener('controlling', () => {
      window.location.reload(true);
    });


    // To prompt the user to refresh the app when there's an update
    this.wb.addEventListener('waiting', () => {
      try {
        this.overlay = true;
        //this.wb.messageSW({ type: 'SKIP_WAITING' });
        //window.location.reload(true);
      } catch (error) {
        console.log(error);

      }
    });

    this.wb.addEventListener('installed', event => {
      //console.log('New Service Worker installed.');
    });

    this.wb.addEventListener('activated', event => {
      // console.log('New Service Worker activated.');
    });

    this.wb.addEventListener('redundant', event => {
      // console.log('New Service Worker redundant.');
    });


    this.wb.addEventListener('externalinstalled', event => {
      // console.log('New Service Worker externalinstalled.');
    });

    this.wb.register();

    this.subscribeToAlerts();
  },
  mounted() {



    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.isStandalone = true;
    }

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', this.updateLastInteractionTime);
    window.addEventListener('blur', this.calculateElapsedTime);
    //window.addEventListener('focus', this.checkForWorkboxUpdates);


  },
  methods: {
    getLabelForAlertType(type) {
      switch (type) {
        case 'checkin_no_reservation':
          return 'Asistencia sin reserva previa, por favor reserve antes de asistir.';
        case 'reservation_not_used':
          return 'Reserva no utilizada, por favor cancele si no va a asistir.';
        case 'user_disabled':
          return 'Usuario deshabilitado, por favor contacte al administrador.';
        case 'user_subscription_expired':
          return 'Uso con suscripcion expirada, por favor renueve su suscripcion.';
        case 'plan_completed':
          return 'Intento asistencia con plan completado, por favor adierase a su plan.';
        case 'user_license_paused':
          return 'Uso con licencia activa, debe desactivar la licencia para poder asistir.';
        case 'late_checkin':
          return 'Asistencia fuera de horario, por favor asista en el horario correspondiente.';
        default:
          return type;
      }
    },
    getColorForAlertType(type) {
      switch (type) {
        case 'checkin_no_reservation':
          return 'info';
        case 'reservation_not_used':
          return 'error';
        case 'user_disabled':
          return 'error';
        case 'user_subscription_expired':
          return 'error';
        case 'plan_completed':
          return 'warning';
        case 'user_license_paused':
          return 'warning';
        case 'late_checkin':
          return 'warning';
        default:
          return 'info';
      }
    },

    subscribeToAlerts() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const db = getFirestore();
          const alertsRef = collection(db, `users/${user.uid}/alerts`);

          // Assuming you want to fetch alerts from the last 2 weeks
          const twoWeeksAgo = new Date();
          twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

          const q = query(alertsRef, where("date", ">=", twoWeeksAgo), orderBy("date", "desc"));

          this.unsubscribeAlerts = onSnapshot(q, (querySnapshot) => {
            this.alerts = [];
            querySnapshot.forEach((doc) => {
              this.alerts.push({ id: doc.id, ...doc.data() });
            });
          });
        } else {
          if (this.unsubscribeAlerts) {
            this.unsubscribeAlerts();
          }
        }
      });
    },
    confirmUpdate() {
      this.loadingBTN = true
      this.wb.messageSW({ type: 'SKIP_WAITING' });
    },
    async checkForWorkboxUpdates() {
      if (this.wb) {
        await this.wb.update();
      }
    },
    addLinkClickListener() {
      const editorContent = document.querySelector('.vue-editor'); // Same selector as before


      if (editorContent) {
        const links = editorContent.querySelectorAll('a');

        // Remove existing event listeners
        links.forEach(link => {
          link.removeEventListener('click', this.handleLinkClick);
        });

        // Add new event listeners
        links.forEach(link => {
          link.addEventListener('click', this.handleLinkClick);
        });
      }
    },
    handleLinkClick(event) {
      event.preventDefault();
      const href = event.currentTarget.getAttribute('href');
      this.$router.push(href);
    },
    updateLastInteractionTime() {
      this.lastInteractionTime = new Date();
    },

    calculateElapsedTime() {
      const currentTime = new Date();
      const elapsed = currentTime - this.lastInteractionTime; // Time in milliseconds

      // Check if the elapsed time is greater than 30 minutes
      if (elapsed > (300000) * 6 && this.$store.state.Auth.isLogged && this.$store.state.Auth.token.claims.type != 'superuser' && this.$store.state.Auth.token.claims.type != 'admin' && this.$store.state.Auth.token.claims.type != 'entrenador') {

        //  check if iphone 

        if (this.isIos) {
          window.location.reload(true);
        }
        // check if android
        if (this.isAndroid) {
          window.location.reload();
        }

      }
      // else if with the same conditions but if elapsed more than a day
      else if (elapsed > ((300000) * 12) * 24 && this.$store.state.Auth.isLogged && (this.$store.state.Auth.token.claims.type == 'superuser' || this.$store.state.Auth.token.claims.type == 'admin' || this.$store.state.Auth.token.claims.type == 'entrenador')) {
        window.location.reload(true);
      }
    },

    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.calculateElapsedTime();
      }
      this.updateLastInteractionTime();
    },
    updateNews(content) {
      this.content = content;

      this.$nextTick(() => {
        this.addLinkClickListener();
      });
    },
    async getNews() {
      this.loadingNews = true;
      const db = getFirestore();
      const docRef = doc(db, `configurations/news`);

      this.unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (!docSnap.exists()) {
          const news = { content: '', updatedAt: new Date() };
          setDoc(docRef, news);
          this.content = '';
          this.noticationUpdatedAt = new Date();
        } else {
          let data = docSnap.data();
          this.content = data.content.replace(/target="_blank"/g, '');
          this.noticationUpdatedAt = new Date(data.updatedAt.seconds * 1000);
          if (this.$refs.editNews) {
            this.$refs.editNews.contentHtml = data.content;
          }
        }

        this.loadingNews = false;
        this.$nextTick(() => {
          this.addLinkClickListener();
        });
      }, (error) => {
        console.error("Error fetching news: ", error);
        this.loadingNews = false;
      });
    },

    toggleDrawer() {
      this.drawer = !this.drawer
    },
    toggleDrawerRight() {
      this.drawer2 = !this.drawer2

      this.$store.dispatch("changeLastNotificationUpdate", true);
    },
    toggleDrawerRightAlerts() {
      this.drawerAlerts = !this.drawerAlerts

      this.$store.dispatch("changeLastAlertUpdate", true);
    },
    logout() {
      this.$store.dispatch("Auth/userLogout");
      LoginManager.logout()
      this.drawer = false
      //check if route is not '/' and redirect to login
      if (this.$route.path != '/') {
        this.$router.push('/login')
      }
    },
    async subscribeGoogleAuth() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.getNews();
          this.$store.dispatch("Auth/userLogin", user);
          this.showPadding = false;
        } else {
          this.logout();
          this.showPadding = true;
        }
      });
    },
  },
  watch: {
    isLogged: {
      handler: function (val) {
        if (val) {
          this.getNews();
        } else {

          LoginManager.logout();

        }
      },
      immediate: true
    }
  }
}


</script>

<style lang="scss">
/* html{
  overflow-y: hidden !important;
} */
#app {
  overflow-y: hidden !important;
}

.v-application--wrap {
  overflow-y: hidden !important;
}

.safeMargin {
  /*   padding-bottom: calc(40px + env(safe-area-inset-bottom));
 */
}

#main-container {
  /*   margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top); */
  /* max-height: calc(100dvh - 54px) ;
    overflow: scroll; */
}

body {
  background-color: var(--v-primary-base);
  overflow-y: hidden !important;
}

#nav {
  transform: none !important;
}

#app.dontShow #nav {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 40px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-btn:focus {
  border: 0px;
  outline: 0px;
}

.theme--dark.v-bottom-navigation {
  background-color: #272727;
  color: #FFFFFF;
}

.isIos {
  padding-bottom: 20px;
}


.rg-btn-container-foreground {
  --border-color: var(--v-primary-base) !important;

  --border-color: var(--v-primary-darken2) !important;
  --icon-color: var(--v-primary-base) !important;
  --background-color: #FFFFFF;
  --title-color: var(--v-primary-base) !important;
  --badge-color: var(--v-error-base) !important;
  box-shadow: 0px 0px !important;

  z-index: 6 !important;
}

.theme--dark .rg-btn-container-foreground {
  color: whitesmoke !important;
  background-color: #272727 !important;
}

.theme--dark .v-date-picker-table .v-btn.v-btn--active {
  color: black !important;
}

.rg-btn-title {
  text-transform: capitalize !important;
}

.rg-btn-container-foreground.isIos {
  padding-bottom: 20px !important;
  height: 84px !important;
}

@media (min-width: 1264px) {
  .isLogged .v-main__wrap>.container {
    max-width: calc(100% - 55px) !important;
    margin-right: 0px !important;
    margin-left: 55px !important;
  }
}

@media (min-width: 960px) {
  .isLogged .v-main__wrap>.container {
    max-width: calc(100% - 55px) !important;
    margin-right: 0px !important;
    margin-left: 55px !important;
  }
}

.glass {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  //backdrop-filter: blur(5.8px) !important;
  //-webkit-backdrop-filter: blur(5.8px) !important;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
}
</style>
