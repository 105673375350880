<template>
    <v-card class="rounded-lg overflow-hidden" elevation="1">
        <v-card-text>
            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="!loading">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <div class="text-subtitle-2 text-medium-emphasis">Total Asistencias</div>
                            <div class="text-h4 font-weight-bold">
                                {{ currentAssistances }}
                            </div>
                        </div>
                        <v-icon :color="assistanceChange >= 0 ? 'success' : 'error'" size="48">
                            {{ assistanceChange >= 0 ? 'mdi-trending-up' : 'mdi-trending-down' }}
                        </v-icon>
                    </div>

                    <div class="mt-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span :class="assistanceChange >= 0 ? 'success--text' : 'error--text'"
                                        class="font-weight-bold">
                                        {{ Math.abs(assistanceChange).toFixed(1) }}%
                                    </span>
                                    <span class="text-medium-emphasis"> vs mes anterior</span>
                                </span>
                            </template>
                            <span>Mes anterior: {{ previousAssistances }} asistencias</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-fade-transition>

            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="loading" class="loader-container">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <v-skeleton-loader type="text" width="100" class="mb-2" />
                            <v-skeleton-loader type="heading" width="160" />
                        </div>
                        <v-skeleton-loader type="avatar" width="48" height="48" />
                    </div>
                    <div class="mt-2">
                        <v-skeleton-loader type="text" width="140" />
                    </div>
                </div>
            </v-fade-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import DashboardService from '@/services/DashboardService'

export default {
    name: 'AssistancesCard',

    data() {
        return {
            loading: true,
            currentAssistances: 0,
            previousAssistances: 0,
            assistanceChange: 0
        }
    },

    mounted() {
        this.fetchData()
    },

    methods: {
        async fetchData() {
            this.loading = true
            try {
                const dashboardData = await DashboardService.getDashboardData()

                this.currentAssistances = dashboardData.assistances.current
                this.previousAssistances = dashboardData.assistances.previous

                // Calculate percentage change
                this.assistanceChange = this.previousAssistances === 0
                    ? 100
                    : ((this.currentAssistances - this.previousAssistances) / Math.abs(this.previousAssistances)) * 100

            } catch (error) {
                console.error('Error fetching assistance data:', error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>