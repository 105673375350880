<template>
  <v-container class="pa-0">
    <!-- Objectives Card -->
    <v-card class="mb-4 glass">
      <v-card-text class="text-left">
        <!-- Objectives Section -->
        <p class="text-h6">
          <v-icon left>
            mdi-bullseye-arrow
          </v-icon>
          Objetivos de Entrenamiento
        </p>
        <p v-show="!isCoachView">
          Selecciona tu/tus objetivos de entrenamiento, esto nos ayudará a personalizar tu plan de entrenamiento.
        </p>
        <v-autocomplete v-model="localUser.objectives" :items="availableObjectives" @change="checkMaxSelection"
          label="Seleccionar" single-line :search-input.sync="search" class="rounded-lg" filled rounded multiple
          :rules="[maxSelection(5, 'objectives')]" chips>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No hay resultados para "<strong>{{ search }}</strong>". Presiona <kbd>enter</kbd> para crear uno
                  nuevo.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-card-text>
    </v-card>

    <!-- Injuries Card -->
    <v-card class="glass">
      <v-card-text class="text-left">
        <!-- Injuries Section -->
        <p class="text-h6">
          <v-icon left>
            mdi-bandage
          </v-icon>
          Lesiones
        </p>
        <p v-show="!isCoachView">
          Por favor, indícanos si has tenido alguna lesión en el pasado o actualmente.
        </p>
        <v-expansion-panels v-model="injuriesPanel">
          <v-expansion-panel v-for="(injury, index) in localUser.injuries" :key="index">
            <v-expansion-panel-header>
              <v-text-field filled rounded class="rounded-lg" label="Nombre de lesión"
                :rules="[v => !!v || 'Este campo es requerido']" maxlength="50" v-model="injury.nombre">
                <template v-slot:append>
                  <v-icon :color="injury.recuperado ? 'green lighten-3' : 'red lighten-3'">
                    mdi-medical-bag
                  </v-icon>
                </template>
              </v-text-field>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                max-width="300" min-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field filled rounded class="rounded-lg mt-2" hide-details v-model="injury.cuando"
                    label="Cuando" append-icon="mdi-calendar" readonly dense v-bind="attrs" v-on="on"
                    required></v-text-field>
                </template>
                <v-date-picker v-model="injury.cuando" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancelar</v-btn>
                  <v-btn text color="primary" @click="menu = false">Confirmar</v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field filled rounded class="rounded-lg mt-2" hide-details label="Deporte" maxlength="50"
                append-icon="mdi-soccer" v-model="injury.deporte"></v-text-field>
              <v-checkbox class="mb-4" hide-details label="Recuperado" v-model="injury.recuperado"></v-checkbox>
              <v-textarea auto-grow rows="1" v-model="injury.observation" filled rounded class="rounded-lg my-2"
                hide-details label="Observaciones" v-if="isAuthorizedUser" maxlength="150"></v-textarea>
              <v-card-actions class="py-0">
                <v-spacer></v-spacer>
                <v-btn @click="removeInjury(index)">
                  <v-icon left>
                    mdi-delete
                  </v-icon>
                  Borrar Lesión
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-actions>

          <v-spacer />

          <v-btn @click="addInjury" class="mt-2">
            <v-icon left>
              mdi-plus
            </v-icon>
            Agregar
          </v-btn>
          <v-spacer />

        </v-card-actions>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { logAuditEvent } from '@/error/audit.js';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    isCoachView: {
      type: Boolean,
      default: false,
    },
    isEvaluationView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      search: '',
      injuriesPanel: [],
      localUser: JSON.parse(JSON.stringify(this.user)),
      availableObjectives: [
        'Desarrollo de la fuerza',
        'Agilidad',
        'Resistencia',
        'Flexibilidad',
        'Velocidad',
        'Potencia',
        'Coordinación',
        'Hipertrofia',
        'Movilidad',
        'Estabilidad',
        'Equilibrio',
        'Postura',
        'Rehabilitación',
        'Prevención de lesiones',
        'Perder peso',
      ],
    };
  },
  computed: {
    isAuthorizedUser() {
      const userType = this.$store.state.Auth.token.claims.type;
      return ['superuser', 'admin', 'entrenador'].includes(userType);
    },
  },
  watch: {
    'localUser.objectives': {
      handler() {
        this.updateUserData();
      },
      deep: true,
    },
    'localUser.injuries': {
      handler() {
        this.updateUserData();
      },
      deep: true,
    },
  },
  methods: {
    checkMaxSelection() {


      if (this.localUser.objectives && this.localUser.objectives.length > 5) {
        this.localUser.objectives.pop()
      }
    },
    addInjury() {
      this.localUser.injuries.push({
        nombre: null,
        cuando: null,
        como: null,
        deporte: null,
        recuperado: false,
        observation: '',
      });
    },
    removeInjury(index) {
      this.localUser.injuries.splice(index, 1);
    },
    async updateUserData() {
      try {
        const db = getFirestore();
        const userRef = doc(db, 'users', this.user.id);


        await updateDoc(userRef, {
          objectives: this.localUser.objectives || [],
          injuries: this.localUser.injuries || [],
        });

        logAuditEvent(
          'update',
          this.$store.state.Auth.token.claims.user_id,
          `User preferences updated ${JSON.stringify(this.localUser)}`
        );
      } catch (e) {
        logAuditEvent(
          'error',
          this.$store.state.Auth.token.claims.user_id,
          `Error updating user preferences ${e.message}`
        );
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Error al actualizar los datos.',
        });
      }
    },
    maxSelection(max, field) {
      return (v) => {
        if (v.length <= max) return true;
        return `Máximo ${max} seleccionados.`;
      };
    },
  },
};
</script>

<style scoped>
.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__selections {
  padding-top: 26px !important;
}
</style>
