<template>
  <v-container>
    <v-row no-gutters>

      <v-flex xs12>
        <v-subheader class="text-h5 my-1">
          <v-divider class="mr-4"></v-divider>

          <v-icon color="primary" class="mr-2" left>
            mdi-lightning-bolt-outline
          </v-icon>
          Utilidades {{ $store.state.Auth.token.claims.type }}

          <v-divider class="ml-4"></v-divider>
        </v-subheader>
      </v-flex>
      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'entrenador')">
        <v-card class="mx-auto  pt-5  fill-height" @click="showData('/qr')">


          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">mdi-qrcode-scan</v-icon>
          </v-img>
          <v-card-text class="">
            Control de acceso <br> Codigo QR <br> Identificación
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" class="pa-2 text-center "
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'entrenador')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/reservas')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">mdi-list-status</v-icon>
          </v-img>
          <v-card-text class="">
            Reservas <br> Asistencias <br>
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin' /* || $store.state.Auth.token.claims.type == 'entrenador' */)">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/usuarios')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">mdi-account-edit-outline</v-icon>
          </v-img>
          <v-card-text class="">
            Usuarios <br> Pagos <br> Evaluaciones
          </v-card-text>
        </v-card>
      </v-col>

      <!--  <v-col cols="6" class="pa-2 text-center"  v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type=='superuser' || $store.state.Auth.token.claims.type == 'admin')">
              <v-card class="mx-auto pt-5 fill-height" max-width="344"  @click="showData('/pagos')">
                <v-img class="white--text align-end">
                  <v-icon color="primary" size="50">mdi-cash

                  </v-icon>
                </v-img>
                <v-card-text class="">
                  Historial de pagos y membresias
                </v-card-text>
              </v-card>
          </v-col>  -->

      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'entrenador')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/rutinas')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">mdi-playlist-edit</v-icon>
          </v-img>
          <v-card-text>
            Planificaciones del centro
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/glosario')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">
              mdi-database-cog
            </v-icon>
          </v-img>
          <v-card-text class="">Glosario



          </v-card-text>
        </v-card>
      </v-col>


      <!--   <v-col cols="6" md="3" class="pa-2 text-center"  v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser')">
            <v-card class="mx-auto pt-5 fill-height"  @click="showData('/checkout')">
              <v-img class="white--text align-end">
                <v-icon color="primary" size="45">
                  mdi-cash-register
                </v-icon>
              </v-img>
              <v-card-text>Checkout</v-card-text>
            </v-card>
      </v-col>  -->

      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/analisis')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">
              mdi-finance
            </v-icon>
          </v-img>
          <v-card-text>
            Analítica del centro
          </v-card-text>
        </v-card>
      </v-col>

      <!--  <v-col cols="6" md="3" class="pa-2 text-center"  v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type=='superuser' || $store.state.Auth.token.claims.type == 'admin')">
            <v-card class="mx-auto pt-5 fill-height"  @click="showData('/horarios')">
              <v-img class="white--text align-end">
                <v-icon color="primary" size="50">mdi-clock-edit-outline</v-icon>
              </v-img>
              <v-card-text>Horarios del centro <br>Dias especiales</v-card-text>
            </v-card>
        </v-col>  -->

      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/tareas')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-checkbox-marked-outline
            </v-icon>
          </v-img>
          <v-card-text class="">Tareas <br> Pendientes</v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/ingresos-egresos')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-cash-sync
            </v-icon>
          </v-img>
          <v-card-text>Ingresos <br> Egresos</v-card-text>
        </v-card>
      </v-col>


      <!--  <v-col cols="6" md="3" class="pa-2 text-center"  v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin') ">
              <v-card class="mx-auto pt-5 fill-height"  @click="showData('/suscripciones')">
                <v-img class="white--text align-end">
                  <v-icon color="primary" size="50">
                    mdi-account-group
                  </v-icon>
                </v-img>
                <v-card-text class="">Suscripciones</v-card-text>
              </v-card>
          </v-col>  -->



      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/evaluaciones')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-calendar-star
            </v-icon>
          </v-img>
          <v-card-text class="">
            Calendario de evaluaciones
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/ajustes')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-cog
            </v-icon>
          </v-img>
          <v-card-text class="">
            Ajustes
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/logs')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-file-document
            </v-icon>
          </v-img>
          <v-card-text class="">
            Logs
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/recursos')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-file-document
            </v-icon>
          </v-img>
          <v-card-text class="">
            Recursos
          </v-card-text>
        </v-card>
      </v-col>


      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser')">
        <v-card class="mx-auto pt-5 fill-height" @click="showData('/encuestas')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-file-document
            </v-icon>
          </v-img>
          <v-card-text class="">
            Formularios
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  methods: {
    showData(route) {
      this.$vuetify.goTo(0);
      this.$router.push(route);
    }
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>
