// LandingPage.vue
<template>
  <v-row :class="['background', $vuetify.theme.dark ? 'background--dark' : '']" align="center" justify="center"
    style="height:100vh" no-gutters>
    <div class="landing-container">
      <v-card class="landing-card" elevation="10">
        <transition-group name="fade-transition">
          <v-col cols="12" class="text-center pb-4 d-flex justify-center" key="logo">
            <v-img
              :src="require($vuetify.theme.dark ? './../assets/logo_y_slogan_blanco.png' : './../assets/logo_y_slogan.png')"
              class="shrink mt-8 mb-6 logo-animation" alt="Sportfit app logo" width="180" contain />
          </v-col>

          <v-col cols="12" class="px-12" key="content">
            <div class="text-center">
              <h2 key="title2" class="headline mb-6">
                <span class="font-weight-bold primary--text">Entrenamientos personalizados</span>
                <br>
                <span class="subtitle-1 grey--text text--darken-1">
                  Seguimiento de progreso y más!
                </span>
              </h2>
            </div>

            <div class="action-buttons">
              <v-btn v-if="showInstallButton" class="action-btn mb-4" large block rounded
                @click="overlayAddToHomeScreen = true">
                <v-icon left>mdi-plus</v-icon>
                Agregar a la pantalla de inicio
              </v-btn>

              <v-btn v-if="showInstallPrompt" class="action-btn mb-4" large block rounded @click="installPWA">
                <v-icon left>mdi-download</v-icon>
                Instalar aplicación
              </v-btn>

              <v-btn x-large block color="primary" rounded elevation="2" height="50" class="login-btn"
                @click="$router.push('/login')">
                <v-icon left>mdi-login</v-icon>
                <span class="font-weight-medium">Iniciar sesion</span>
              </v-btn>
            </div>
          </v-col>
        </transition-group>
      </v-card>

      <!-- Location Card -->
      <v-card class="location-card mt-4" elevation="2">
        <div class="location-content">
          <div class="location-icon-wrapper">
            <v-icon size="32" color="primary">mdi-map-marker</v-icon>
          </div>
          <div class="location-details">
            <h3 class="location-title">Visítanos</h3>
            <p class="location-address">Magallanes 1017, Montevideo</p>
          </div>
        </div>
        <v-btn block color="primary" outlined class="mt-3 location-button"
          href="https://maps.app.goo.gl/hnGEHeBnviiqgFoAA" target="_blank" rel="noopener noreferrer">
          <v-icon left>mdi-navigation</v-icon>
          Cómo llegar
        </v-btn>
      </v-card>
    </div>

    <v-overlay v-model="overlayAddToHomeScreen" color="black" opacity="0.8">
      <v-card class="mx-auto" max-width="300">
        <v-card-title class="text-h5">Agregar a inicio
          <v-spacer></v-spacer>

          <v-icon @click="overlayAddToHomeScreen = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <template v-if="isSafari">
            <p>Para agregar esta aplicación a la pantalla de inicio de su dispositivo iOS, siga los siguientes pasos:
            </p>

            <ol>
              <li>Abra la aplicación en Safari en su dispositivo iOS.</li>
              <li>Toque el icono de compartir
                <v-icon>mdi-export-variant</v-icon>
              </li>
              <li>Toque "Agregar a inicio"
                <v-icon>mdi-plus-box-outline</v-icon>
              </li>
              <li>Y listo! Busque el icono en su pantalla de inicio.</li>
            </ol>
          </template>

          <template v-else>
            <p>Para agregar esta aplicación a la pantalla de inicio:</p>

            <ol>
              <li>Toque "Agregar a inicio"
                <v-icon>mdi-plus-box-outline</v-icon>
              </li>
              <li>Y listo! Busque el icono en su pantalla de inicio.</li>
            </ol>
          </template>
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="overlayAddToHomeScreen = false">Cerrar</v-btn>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-row>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      showInstallPrompt: false,
      deferredPrompt: null,
      overlayAddToHomeScreen: false,
    }
  },
  methods: {
    async installPWA() {
      if (this.deferredPrompt) {
        this.showInstallPrompt = false;
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        //console.log(`User response to the install prompt: ${outcome}`);
        this.deferredPrompt = null;
      }
    },
  },
  mounted() {
    this.$vuetify.goTo(0);
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.showInstallPrompt = true;
    });
  },
  computed: {
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    isStandalone() {
      return (window.navigator.standalone !== undefined) ? window.navigator.standalone : false;
    },
    showInstallButton() {
      return this.isIOS && !this.isStandalone;
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
  },
};
</script>

<style scoped lang="scss">
.background {
  background: linear-gradient(135deg, #0ab1ec 0%, #0892c3 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.05;
  }

  &--dark {
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);

    &::before {
      opacity: 0.1;
    }
  }
}

.landing-container {
  position: relative;
  max-width: 500px;
  width: 100%;
  margin: 0 20px;
  z-index: 1;
}

.landing-card {
  border-radius: 24px;
  padding: 20px 0;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
}

.logo-animation {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.action-buttons {

  .action-btn,
  .login-btn {
    text-transform: none;
    letter-spacing: 0.5px;
    font-weight: 500;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 15px rgba(10, 177, 236, 0.3) !important;
    }
  }
}

// Dark theme adjustments
:deep(.v-application.theme--dark) {
  .landing-card {
    background-color: rgba(30, 30, 30, 0.95);
    backdrop-filter: blur(20px);
  }
}

.headline {
  line-height: 1.5;
  letter-spacing: 0.3px;

  .subtitle-1 {
    display: block;
    margin-top: 8px;
    font-size: 1.1rem;
    font-weight: 400;
  }
}

.location-card {
  border-radius: 20px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.location-content {
  display: flex;
  align-items: center;
}

.location-icon-wrapper {
  border-radius: 12px;
  padding: 12px;
  margin-right: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.location-details {
  flex: 1;
}

.location-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--v-primary-darken1);
  margin-bottom: 4px;
}

.location-address {
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.4;
}

.location-button {
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: 500;
  border-radius: 12px;
  height: 48px;
  transition: all 0.3s ease;
}

.location-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
</style>