<template>
    <v-container>
        <v-card class="elevation-1 rounded-lg">
            <v-card-title class="d-flex align-center py-3">
                <v-icon left color="primary">mdi-calendar-clock</v-icon>
                <span class="text-h6">Fix User Creation Dates</span>
            </v-card-title>

            <v-card-text>
                <v-data-table mobile-breakpoint="0" v-model="selected" :headers="headers" :items="users"
                    :loading="loading" show-select class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                hide-details class="mx-4"></v-text-field>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :disabled="!selected.length" :loading="fixing"
                                @click="fixSelectedDates">
                                <v-icon left>mdi-calendar-check</v-icon>
                                Fix Selected ({{ selected.length }})
                            </v-btn>
                        </v-toolbar>
                    </template>

                    <template v-slot:item.createdAt="{ item }">
                        <template v-if="item.createdAt">
                            <v-chip :color="isValidDate(item.createdAt) ? 'success' : 'error'" small class="mr-2">
                                {{ formatDate(item.createdAt) }}
                            </v-chip>
                            <v-icon small :color="isValidDate(item.createdAt) ? 'success' : 'error'">
                                {{ isValidDate(item.createdAt) ? 'mdi-check' : 'mdi-alert' }}
                            </v-icon>
                        </template>
                        <v-chip v-else color="warning" small>
                            Missing
                        </v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn small text color="primary" @click="openDateDialog(item)">
                            <v-icon left small>mdi-calendar-edit</v-icon>
                            Set Date
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- Date Selection Dialog -->
        <v-dialog v-model="dateDialog.show" max-width="400px">
            <v-card>
                <v-card-title>
                    Set Creation Date
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dateDialog.show = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <p class="mb-2">User: {{ dateDialog.user?.displayName }}</p>
                            <v-menu ref="menu" v-model="dateDialog.menuDate" :close-on-content-click="false"
                                transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="dateDialog.date" label="Date" persistent-hint
                                        prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" readonly></v-text-field>
                                </template>
                                <v-date-picker v-model="dateDialog.date" no-title
                                    @input="dateDialog.menuDate = false"></v-date-picker>
                            </v-menu>

                            <v-text-field v-model="dateDialog.time" label="Time" type="time"
                                prepend-icon="mdi-clock-outline" class="mt-4"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="fixing" @click="updateSingleDate">
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { getFirestore, collection, getDocs, doc, updateDoc, Timestamp } from 'firebase/firestore';
import moment from 'moment';

export default {
    name: 'FixUserDates',

    data: () => ({
        loading: false,
        fixing: false,
        search: '',
        selected: [],
        users: [],
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Name', value: 'displayName' },
            { text: 'Email', value: 'email' },
            { text: 'Created At', value: 'createdAt' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        dateDialog: {
            show: false,
            user: null,
            date: null,
            time: null,
            menuDate: false,
        }
    }),

    async created() {
        await this.loadUsers();
    },

    methods: {
        async loadUsers() {
            try {
                this.loading = true;
                const db = getFirestore();
                const querySnapshot = await getDocs(collection(db, 'users'));

                this.users = querySnapshot.docs.map(doc => ({

                    ...doc.data(),
                    id: doc.id
                }));
            } catch (error) {
                console.error('Error loading users:', error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Failed to load users'
                });
            } finally {
                this.loading = false;
            }
        },

        isValidDate(date) {
            if (!date) return false;
            return date && date.seconds !== undefined && date.nanoseconds !== undefined;
        },

        formatDate(date) {
            if (!date) return 'N/A';
            if (date.seconds) {
                return moment(date.seconds * 1000).format('YYYY-MM-DD HH:mm:ss');
            }
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },

        openDateDialog(user) {
            this.dateDialog.user = user;
            this.dateDialog.show = true;

            // Set default date and time
            const defaultDate = user.createdAt ?
                (user.createdAt.seconds ?
                    moment(user.createdAt.seconds * 1000) :
                    moment(user.createdAt)
                ) :
                moment();

            this.dateDialog.date = defaultDate.format('YYYY-MM-DD');
            this.dateDialog.time = defaultDate.format('HH:mm');
        },

        async updateSingleDate() {
            if (!this.dateDialog.date || !this.dateDialog.time) {
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Please select both date and time'
                });
                return;
            }

            try {
                this.fixing = true;
                const datetime = moment(`${this.dateDialog.date} ${this.dateDialog.time}`, 'YYYY-MM-DD HH:mm');
                await this.updateUserDate(this.dateDialog.user, datetime.toDate());

                this.dateDialog.show = false;
                await this.loadUsers();

                this.$notify({
                    group: 'feedback',
                    type: 'success',
                    title: 'Success',
                    text: 'Date updated successfully'
                });
            } catch (error) {
                console.error('Error updating date:', error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Failed to update date'
                });
            } finally {
                this.fixing = false;
            }
        },

        async fixSelectedDates() {
            try {
                this.fixing = true;
                let updated = 0;

                for (const user of this.selected) {
                    if (!this.isValidDate(user.createdAt)) {
                        const date = moment('2023-12-02', 'YYYY-MM-DD').toDate();

                        await this.updateUserDate(user, date);
                        updated++;
                    }
                }

                await this.loadUsers();
                this.selected = [];

                this.$notify({
                    group: 'feedback',
                    type: 'success',
                    title: 'Success',
                    text: `Updated ${updated} users successfully`
                });
            } catch (error) {
                console.error('Error fixing dates:', error);
                this.$notify({
                    group: 'feedback',
                    type: 'error',
                    title: 'Error',
                    text: 'Failed to update dates'
                });
            } finally {
                this.fixing = false;
            }
        },

        async updateUserDate(user, date) {
            const db = getFirestore();
            const userRef = doc(db, 'users', user.id);
            await updateDoc(userRef, {
                createdAt: Timestamp.fromDate(date)
            });
        }
    }
};
</script>