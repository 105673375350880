<template>
    <v-card class="rounded-lg overflow-hidden" max-height="300px" elevation="1">
        <v-skeleton-loader v-if="loading" type="image" color="primary"></v-skeleton-loader>
        <v-card-text class="pa-0" v-if="chartData.length > 0">
            <apexchart type="bar" :options="chartOptions" :series="chartData" height="300">
            </apexchart>
        </v-card-text>
        <v-alert class="mb-0" v-if="!loading && chartData.length === 0" type="info" text>
            No hay clases programadas para hoy.
        </v-alert>

    </v-card>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';

export default {
    name: 'TodaySchedules',
    components: {
        apexchart: VueApexCharts
    },
    data() {
        return {
            loading: false,
            chartOptions: {
                title: {
                    text: 'Asistencias Día Actual',
                    align: 'left',
                    style: {
                        fontSize: '14px',
                        fontWeight: 500
                    },
                    margin: 35,
                    offsetX: 10
                },
                theme: {
                    mode: this.$store.state.isDark ? 'dark' : 'light',
                    palette: 'palette1',
                },
                chart: {
                    background: this.$store.state.isDark ? 'rgb(30, 30, 30)' : '#fff',
                    toolbar: {
                        show: false
                    },
                    id: 'today-class-stats',
                    stacked: true,
                    height: '300px',

                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    title: {
                        text: 'Cantidad'
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'center'
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px',
                        colors: ["#fff"]
                    },
                    dropShadow: {
                        enabled: false
                    }
                },
                legend: {
                    show: this.showLegend,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    markers: {
                        radius: 50,
                        strokeWidth: 0
                    }
                }
            },
            chartData: []
        };
    },
    computed: {
        showLegend() {
            return !this.$vuetify.breakpoint.mobile;
        }
    },
    watch: {
        showLegend(val) {
            this.chartOptions.legend.show = val;
        },
        '$store.state.isDark'(isDark) {
            this.chartOptions.chart.background = isDark ? 'rgb(30, 30, 30)' : '#fff';
            this.chartOptions.theme.mode = isDark ? 'dark' : 'light';
        }
    },
    methods: {
        async fetchData() {
            this.loading = true;

            const today = moment().format('YYYY-MM-DD');

            const db = getFirestore();
            const scheduleRef = collection(db, `schedule/${today}/schedules`);
            const snapshot = await getDocs(scheduleRef);

            let classStats = {};

            if (!snapshot.empty) {

                snapshot.forEach((classDoc) => {
                    const classData = classDoc.data();
                    const classType = classData.type;
                    let time;
                    let startTime;

                    if (classData.startDate && classData.startDate.seconds) {
                        time = moment(classData.startDate.seconds * 1000).format('hh:mm A');
                        startTime = new Date(classData.startDate.seconds * 1000);
                    } else {
                        time = moment(classData.startDate).format('hh:mm A');
                        startTime = new Date(classData.startDate);
                    }

                    if (!classStats[time]) {
                        classStats[time] = {};
                    }
                    if (!classStats[time][classType]) {
                        classStats[time][classType] = { checkedIn: 0, notAttended: 0, lateCheckedIn: 0 };
                    }

                    classData.users.forEach(user => {
                        if (user.checkedIn) {
                            const checkInTime = new Date(user.checkedIn);
                            if (checkInTime - startTime > 10 * 60 * 1000) {
                                classStats[time][classType].lateCheckedIn++;
                            } else {
                                classStats[time][classType].checkedIn++;
                            }
                        } else {
                            classStats[time][classType].notAttended++;
                        }
                    });
                });
            }

            let seriesData = {
                checkedIn: [],
                notAttended: [],
                lateCheckedIn: []
            };

            let sortedTimes = Object.keys(classStats).sort((a, b) => {
                const aTime = new Date(`2021-01-01 ${a}`);
                const bTime = new Date(`2021-01-01 ${b}`);
                return aTime - bTime;
            });

            for (const time of sortedTimes) {
                for (const [type, stats] of Object.entries(classStats[time])) {
                    seriesData.checkedIn.push({
                        x: `${time} - ${type}`,
                        y: stats.checkedIn
                    });
                    seriesData.notAttended.push({
                        x: `${time} - ${type}`,
                        y: stats.notAttended
                    });
                    seriesData.lateCheckedIn.push({
                        x: `${time} - ${type}`,
                        y: stats.lateCheckedIn
                    });
                }
            }


            this.chartOptions.xaxis.categories = sortedTimes;

            if (seriesData.checkedIn.length > 0 || seriesData.lateCheckedIn.length > 0 || seriesData.notAttended.length > 0) {
                this.chartData = [
                    {
                        name: 'Asistencias',
                        data: seriesData.checkedIn,
                        color: '#4CAF50' // success green
                    },

                    {
                        name: 'Asistencias tardías',
                        data: seriesData.lateCheckedIn,
                        color: '#2d9437' // success darken-2
                    },
                    {
                        name: 'Reserva sin uso',
                        data: seriesData.notAttended,
                        color: '#9E9E9E' // grey
                    },
                ];
            } else {
                this.chartData = [];
            }



            this.loading = false;
        }
    },
    mounted() {
        this.fetchData();
    }
};
</script>

<style scoped></style>