<template>
    <v-card class="rounded-lg overflow-hidden" elevation="1">
        <v-card-text>
            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="!loading">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <div class="text-subtitle-2 text-medium-emphasis">Margen de Ganancia</div>
                            <div class="text-h4 font-weight-bold">
                                {{ formatCurrency(currentProfit) }}
                            </div>
                        </div>
                        <v-icon :color="profitChange >= 0 ? 'success' : 'error'" size="48">
                            {{ profitChange >= 0 ? 'mdi-trending-up' : 'mdi-trending-down' }}
                        </v-icon>
                    </div>

                    <div class="mt-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span :class="profitChange >= 0 ? 'success--text' : 'error--text'"
                                        class="font-weight-bold">
                                        {{ Math.abs(profitChange).toFixed(1) }}%
                                    </span>
                                    <span class="text-medium-emphasis"> vs mes anterior</span>
                                </span>
                            </template>
                            <span>Mes anterior: {{ formatCurrency(previousProfit) }}</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-fade-transition>

            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="loading" class="loader-container">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <v-skeleton-loader type="text" width="120" class="mb-2" />
                            <v-skeleton-loader type="heading" width="180" />
                        </div>
                        <v-skeleton-loader type="avatar" width="48" height="48" />
                    </div>
                    <div class="mt-2">
                        <v-skeleton-loader type="text" width="150" />
                    </div>
                </div>
            </v-fade-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import DashboardService from '@/services/DashboardService'

export default {
    name: 'MarginsCard',

    data() {
        return {
            loading: true,
            currentProfit: 0,
            previousProfit: 0,
            profitChange: 0
        }
    },

    mounted() {
        this.fetchData()
    },

    methods: {
        formatCurrency(value) {
            return new Intl.NumberFormat('es-UY', {
                style: 'currency',
                currency: 'UYU'
            }).format(value)
        },

        async fetchData() {
            this.loading = true
            try {
                const dashboardData = await DashboardService.getDashboardData()

                this.currentProfit = dashboardData.margins.current
                this.previousProfit = dashboardData.margins.previous

                // Calculate percentage change
                this.profitChange = this.previousProfit === 0
                    ? 100
                    : ((this.currentProfit - this.previousProfit) / Math.abs(this.previousProfit)) * 100

            } catch (error) {
                console.error('Error fetching margin data:', error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>